<template>
  <div>
    res {{result}}
    <ac-switch-button
      :disabled="disabled"
      @change="toggle"
      v-model="result"
    ></ac-switch-button>
    <button @click="test">change</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: false,
      disabled: true
    };
  },
  methods: {
    toggle(data) {
      console.log("in example", data);
    },
    test(){
      this.result = true,
      this.disabled = false;
    }
  }
};
</script>
