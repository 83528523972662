<template>
  <div id="app">
    <h2>Default</h2>
    <ac-star></ac-star>
    <h2>Half Stars</h2>
    <ac-star :increment="0.5"></ac-star>
    <h2>Bordered Stars</h2>
    <ac-star :border-width="3"></ac-star>
    <h2>Red Stars</h2>
    <ac-star active-color="#9c0000"></ac-star>
    <h2>Vibrant Stars</h2>
    <ac-star inactive-color="#e1bad9" active-color="#cc1166"></ac-star>
    <h2>Small Stars</h2>
    <ac-star :star-size="20"></ac-star>
    <h2>Big Stars</h2>
    <ac-star :star-size="90"></ac-star>
    <h2>Fluid Stars</h2>
    <ac-star :increment="0.01" :fixed-points="2"></ac-star>
    <h2>Preset Stars</h2>
    <ac-star :rating="4"></ac-star>
    <h2>Custom Star Shape</h2>
    <ac-star
      :border-width="4"
      border-color="#d8d8d8"
      :rounded-corners="true"
      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
    ></ac-star>
    <h2>Non rounded start rating</h2>
    <ac-star :rating="4.67" :round-start-rating="false"></ac-star>
    <h2>Read Only Stars</h2>
    <ac-star :rating="3.8" :read-only="true" :increment="0.01"></ac-star>
    <h2>Lots of stars</h2>
    <ac-star :max-rating="10" :star-size="20"></ac-star>
    <h2>RTL Stars</h2>
    <ac-star :rtl="true" :increment="0.5"></ac-star>
    <h2>Style Rating</h2>
    <ac-star text-class="custom-text"></ac-star>
    <h2>Hide Rating</h2>
    <ac-star :show-rating="false"></ac-star>
    <h2>Inline Stars</h2>
    <p>
      Rated
      <ac-star :inline="true" :star-size="20" :read-only="true" :show-rating="false" :rating="5"></ac-star>by our customers.
    </p>
    <h2>Glowing Stars</h2>
    <div>
      <!-- style="background:#000;padding-bottom:10px;"> -->
      <ac-star
        :glow="10"
        :rounded-corners="true"
        :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
      ></ac-star>
    </div>
    <h2>Capture events currentRating and ratingSelected</h2>
    <ac-star
      :rating="2"
      @ratingSelected="ratingSelected($event)"
      @currentRating="currentRating($event)"
    ></ac-star>
  </div>
</template>
<script>
export default {
  methods: {
    ratingSelected(rating) {
      console.log("RatingSelected " + rating);
    },
    currentRating(rating) {
      console.log("currentRating " + rating);
    }
  },
  data() {
    return {
      rating: 2
    };
  }
};
</script>