<template>
  <ac-collection
    ref="ac_collection"
    :url="collection.url"
    :method="collection.method"
    :ac_cursor="{}"
    :params="collection.params"
    :loading_image="collection.loading_image"
    :sort_key="collection.sort_key"
    pagination_type="loadMore"
    :total_count="20"
    :limit="5"
    limit_key="_limit"
    offset_key="_start"
  >
    <template #header>
      <header class="border p-2 mb-2">Ac collection header</header>
    </template>

    <template #body="{ data }">
      <b-row>
        <ac-draggable v-model="data">
          <b-col v-for="item in data" :key="item.id" class="ac__item mb-3" :lg="6">
            <!-- <b-card :title="item.name" class="mb-2">
                  <b-card-text>{{ item.body }}</b-card-text>
            </b-card>-->
            <b-card class="h-100 card-head-bg shadow-sm">
              <b-card-title>
                <div v-if="item.name" :title="item.name" class="ellipse">{{item.name}}</div>
              </b-card-title>
              <b-card-text>{{ item.body }}</b-card-text>
            </b-card>
          </b-col>
        </ac-draggable>
      </b-row>
    </template>
    <template #empty_state>
      <p>From empty_state Slot</p>
    </template>
    <template #footer>
      <footer class="border p-2 mt-2">ac collection footer</footer>
    </template>
  </ac-collection>
</template>

<script>
export default {
  data() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "email"
      }
    };
  }
};
</script>

<style scoped>
.card-head-bg .card-title .ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
