<template>
  <div>
    <ac-file v-model="file" ref="file-input" class="mb-2"></ac-file>

    <b-button @click="clearFiles" class="mr-2">Reset via method</b-button>
    <b-button @click="file = null">Reset via v-model</b-button>

    <p class="mt-2">
      Selected file:
      <b>{{ file ? file.name : '' }}</b>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null
    };
  },
  methods: {
    clearFiles() {
      console.log("from reset method");
      this.$refs["file-input"].reset();
    }
  }
};
</script>