<template>
  <div>
    <b-button @click="clear_message" variant="danger">Clear Editor</b-button>
    <b-button @click="update_placeholder" variant="warning">Update editor placeholder</b-button>
    <div>Selected mentions: {{selectedMentions}}</div>
    <ac-compose-message
      v-model="content"
      :editor_placeholder="placeholder"
      :mentions="mentions"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @input="onContentChange($event)"
      @key_press="key_press($event)"
      @key_down="key_down($event)"
      @key_up="key_up($event)"
      @mentions="get_mentions($event)"
    >
      <template slot="attachment">
        <!-- class mt-auto should be must added to make it align to the component elements -->
        <b-dropdown
          variant="outline-secondary"
          class="mt-auto ac-compose-message-custom-attachment"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template slot="button-content">
            <i class="fal fa-paperclip"></i>
          </template>
          <b-dropdown-item href="#">Action</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-item href="#">Something else here...</b-dropdown-item>
        </b-dropdown>
      </template>
    </ac-compose-message>
    {{content}}
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      content: "<p>Hi I am Example!</p>",
      selectedMentions: null,
      placeholder: "Compose an epic!",
      mentions: [
        {
          trigger: "@",
          list: [
            {
              id: 1,
              value: "Sai Vikas",
              status: '<i class="far fa-circle fa-sm text-success small"></i>',
              username: "whoami",
              email: "saivikas@500apps.com",
              user_status:
                '<i class="far fa-circle fa-sm text-success small"> <strong>Make earth green !!!</strong> </i> '
            },
            {
              id: 2,
              value: "Shalu Jain",
              email: "shalujain@500apps.com"
            },
            {
              id: 3,
              value: "Sai Ram",
              email: "sairam@500apps.com"
            },
            {
              id: 4,
              value: "Divya",
              image_url: "https://tineye.com/images/widgets/mona.jpg",
              email: "sairam@500apps.in.com"
            },
            {
              id: 5,
              value: "Sai Vikas",
              email: "sairam@500apps.out.com"
            },
            {
              id: 6,
              value: "Shalu Jain",
              email: "sairam@500apps.inside.com"
            },
            {
              id: 7,
              value: "Sai Vikas",
              email: "sairam@500apps.outside.com"
            },
            {
              id: 8,
              value: "Shalu Jain",
              email: "sairam@500apps.active.com"
            },
            {
              id: 9,
              value: "Sai Vikas",
              email: "sairam@500apps.inactive.com"
            },
            {
              id: 99,
              value: "Shalu Jain",
              email: "sairam@500apps.demo.com"
            }
          ]
        },
        {
          trigger: "#",
          list: [{ id: 3, value: "Appup " }, { id: 4, value: "AgileCRM " }]
        }
      ]
    };
  },
  methods: {
    clear_message() {
      this.content = "";
    },
    update_placeholder() {
      this.placeholder = "Update epic!";
    },
    key_press(e) {
      //console.log('Press');
    },
    key_up(e) {
      //console.log('Up');
    },
    get_mentions(e) {
      this.selectedMentions = e;
    },
    key_down(e) {
      // if(e.key == "Enter" && e.altKey == false && e.shiftKey == false && e.ctrlKey == false){
      //   console.log(e);
      //   alert('send message');
      // }
    },
    onEditorBlur(quill) {
      //console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      //console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      //console.log("editor ready!", quill);
    },
    onContentChange(html) {
      //console.log(html)
    }
  }
};
</script>
<style>
.ac-compose-message-custom-attachment {
  margin-bottom: 2px;
  margin-left: 2px;
}
</style>
