<template>
  <ac-form-preview :fields = "form.fields" :buttons="form.buttons"  :template="temp">
     <template #form_preview = "{ data }">
       {{data}}
     </template>
      <template #empty_state>pls fill the form</template>
  </ac-form-preview>
</template>
<script>
export default {
  data() {
    return {
      temp: '<div>name is {{name}}<br> Email is {{email}}</div>',
           form: {
        fields: [
          {
            type: "text",
            name: "name",
            label: "Name"
          },
          {
            type: "email",
            name: "email",
            label: "Email"
          }
        ],
        buttons: [
          {
            type: "submit",
            label: "submit"
          }
        ]
      }
    };
  }
};
</script>