<template>
    <div>
        <ac-filter-form
              v-if="ac_collection_filter"
              :data="filter_fields"
              :reference="ac_collection_filter"
              url="http://div-art.com"
              :fetch_url="'test.com'"
              :post_url="'post.com'"
              :save_submit_route="'/next'"
              :loading_image="'default.png'"
              :validate="true"
            ></ac-filter-form>

            <br />

            <ac-collection
              ref="ac_collection_filter"
              :url="collection.url"
              :method="collection.method"
              :ac_cursor="collection.ac_cursor"
              :params="collection.params"
              :loading_image="collection.loading_image"
              :sort_key="collection.sort_key"
            >
              <template #header>
                <header class="border p-2 mb-2">
                  ac collection header
                </header>
              </template>

              <template #body="{ data }">
                <b-row>
                  <b-col
                    v-for="item in data"
                    :key="item.id"
                    class="ac__item"
                    :lg="6"
                  >
                     <b-card
                      :title="item.name"
                      class="mb-2"
                    >
                      <b-card-text> {{ item.body }}</b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>

              <template #footer>
                <footer class="border p-2 mt-2">
                  ac collection footer
                </footer>
              </template>
            </ac-collection>
    </div>
</template>

<script>
export default {
  data: function () {
      return {
          ac_collection_filter: null,
          filter_fields: [
        {
          tag: 'ac-select',
          value: null,
          type: 'email',
          options: [
            {
              value: null,
              text: 'All Posts',
            }, {
              value: 1,
              text: 'Post 1',
            }, {
              value: 2,
              text: 'Post 2',
            }, {
              value: 3,
              text: 'Post 3',
            },
          ],
          label: 'Posts',
          name: 'postId',
        },
      ],
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        }
      },


      }
  },
mounted(){
    this.$refs.ac_collection_filter.set_ajax();
    this.ac_collection_filter = this.$refs.ac_collection_filter
    console.log(this.$refs)
}
}
</script>

<style>

</style>
