<template>
  <div>
    <ac-checkbox
      id="checkbox-1"
      v-model="status"
      name="checkbox-1"
      text="askdhgkhgas"
      value="checked"
      unchecked-value="unchecked"
    >sndbcshvdc</ac-checkbox>
    <p class="ml-4">{{ status }}</p>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      status: "sjgfksdufyg"
    };
  }
};
</script>

<style>
</style>
