<template>
  <ac-groupcard :carddata="carddata" :headersdata="headersdata" :dropdowns="dropdowns" />
</template>

<script>
export default {
  data() {
    return {
      headersdata: {
        headername: "Recent Activity",
        icons: "far fa-info-circle",
        rcicon: "fal fa-ellipsis-h"
      },
      carddata: [
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-success",
           width:"42.8571%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-warning",
            width:"42.8571%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-success",
            width:"50%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-success",
            width:"60%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-warning",
            width:"30%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
           prog1:"9px",
           color:"bg-success",
            width:"80%"
        },
        {
          profile:
            "https://pbs.twimg.com/profile_images/1006005071232921601/fDl4HEk0_normal.jpg",
          name: "Albert",
          time: "Tue  Jul 09, 2019 04:07:06 pm",
          link:
            "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          url: "https://assets.track.ly/1453/2040/1562668626582_screenshot.png",
          prog1:"9px",
           color:"bg-warning",
            width:"20%"
        }
      ],
      dropdowns: [
        { dname: "Edit" },
        { dname: "Delete" },
        { dname: "View Activities" }
      ]
    };
  }
};
</script>

<style>
</style>
