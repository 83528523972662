<template>
    <ac-nested-dropdown key_value="id" :nested_dropdown_list="nestedDropdown">
        <template #btn_slot>
            <p>Dropdown</p>
        </template>
    </ac-nested-dropdown>
</template>

<script>
export default {
    data() {
      return {
        nestedDropdown: [{
          "text": "asgdgasjsda",
          "keyvalue": "id",
          "id": "23",
          "isSubMenu": true,
          "subMenuList": [{
            "text": "asgdgasjssdsdda",
            "isSubMenu": false,
            "keyvalue": "id",
            "id": "24"
          }, {
            "text": "sndsmasnfmsnf",
            "isSubMenu": true,
            "keyvalue": "id",
            "id": "25",
            "subMenuList": [{
              "text": "asgdgasjssdsdda",
              "isSubMenu": false,
              "keyvalue": "id",
              "id": "26"
            }, {
              "text": "sndsmasnfmsnf",
              "isSubMenu": true,
              "keyvalue": "id",
              "id": "27"
            }]
          }]
        }, {
          "text": "asgdgasjsda",
          "isSubMenu": false,
          "keyvalue": "id",
          "id": "28"
        }]
      }
    }
}
</script>

