<template>
  <ac-dynamic
    :props="{list: ['list', 'of', 'options'], switchoff: false, value: dynamic.value}"
    @select="handleChangeDynamic"
    :props_mapper="{
                list: 'options',
                switchoff: 'disabled',
                testing: 'someprop',
                value: 'value'
              }"
    component_name="VueMultiselect"
    :url="['https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css', 'https://unpkg.com/vue-multiselect@2.1.0']"
  ></ac-dynamic>
</template>

<script>
export default {
  data: function() {
    return {
      dynamic: {
        value: "list"
      }
    };
  },
  methods: {
    handleChangeDynamic(val) {
      this.dynamic = Object.assign({}, this.dynamic, { value: val });
    }
  }
};
</script>

<style>
</style>
