<template>
  <div>
    <ac-filter-form-advanced
      v-if="ac_collection_filter_advanced"
      :data="filter_advanced_fields"
      :reference="ac_collection_filter_advanced"
      url="http://div-art.com"
      :fetch_url="'test.com'"
      :post_url="'post.com'"
      :save_submit_route="'/next'"
      :loading_image="'default.png'"
      :validate="true"
      save_url="http://div-art.com"
      apply_type="onSave"
    >
      <b-button block type="submit" variant="primary">Apply</b-button>
    </ac-filter-form-advanced>

    <br>

    <ac-collection
      ref="ac_collection_filter_advanced"
      :url="collection.url"
      :method="collection.method"
      :ac_cursor="collection.ac_cursor"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
    >
      <template #header>
        <header class="border p-2 mb-2">ac collection header</header>
      </template>

      <template #body="{ data }">
        <b-row>
          <b-col v-for="item in data" :key="item.id" class="ac__item mb-2" :lg="6">
            <b-card :title="item.name" class="mb-2">
              <b-card-text>{{ item.body }}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </template>

      <template #footer>
        <footer class="border p-2 mt-2">ac collection footer</footer>
      </template>
    </ac-collection>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      ac_collection_filter_advanced: null,
      filter_advanced_fields: [
        {
          "filter-label": "Tags",
          "filter-type": "tags",
          "filter-name": "tags",
          "filter-checkbox": true,
          "field-json": {
            type: "multiselect",
            name: "contact_tags",
            label: "Enter Tags",
            url: "https://api.myjson.com/bins/1cfe5k",
            key_value: "value",
            key_label: "label"
          }
        },
        {
          "filter-label": "Score",
          "filter-type": "number",
          "filter-name": "score",
          "filter-checkbox": true,
          "field-json": {
            type: "number",
            name: "score",
            label: "score"
          },
          "filter-cdata": {}
        },
        {
          "filter-label": "Created",
          "filter-checkbox": true,
          "filter-type": "date",
          "filter-name": "created_on",
          "field-json": {
            type: "date",
            name: "date",
            label: "Created"
          },
          "filter-cdata": {}
        },
        {
          "filter-label": "Name",
          "filter-checkbox": true,
          "filter-type": "string",
          "filter-name": "name",
          "field-json": {
            type: "text",
            name: "first_name",
            label: "Name"
          },
          "filter-cdata": {}
        },
        {
          "filter-label": "Owner",
          "filter-checkbox": true,
          "filter-type": "boolean",
          "filter-name": "owner",
          "field-json": {
            type: "dropdown",
            name: "owner_id",
            label: "select owner",
            key_value: "value",
            key_label: "label",
            url: "http://www.mocky.io/v2/5b507e3a3600003b14dd0e66"
          },
          "filter-cdata": {}
        },
        {
          "filter-label": "Custom",
          "filter-checkbox": true,
          "filter-name": "custom",
          "filter-type": "custom",
          "custom-field-json": {
            type: "dropdown",
            label: "Condition",
            option: [
              {
                label: "Test",
                value: "Test"
              }
            ]
          },
          "field-json": {
            type: "text",
            name: "first_name",
            label: "Name"
          },
          "filter-cdata": {}
        }
      ],
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      }
    };
  },
  mounted() {
    this.$refs.ac_collection_filter_advanced.set_ajax();
    this.ac_collection_filter_advanced = this.$refs.ac_collection_filter_advanced;
    console.log(this.$refs);
  }
};
</script>

<style>
</style>
