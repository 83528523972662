<template>
  <ac-collection-grouped-accordion
    ref="ac_collection_grouped"
    :url="collection.url"
    :method="collection.method"
    :params="collection.params"
    :loading_image="collection.loading_image"
    :sort_key="collection.sort_key"
    :ac_cursor="{}"
    :show_pagination="show_pagination"
    :total_count="total_count"
    :limit="limit"
    :offset_key="offset_key"
    :limit_key="limit_key"
    group_key="postId"
  >
    <template v-slot:header>
      <h2>Main Header</h2>
    </template>

    <template v-slot:group_header="{ groupItem }">
      <h3>group Header {{ groupItem.email }}</h3>
    </template>

    <template v-slot:group_accordion_label="{ groupItem }">Accordion label {{ groupItem.name }}</template>

    <template v-slot:body="{ data }" class="item">
      <div v-for="(value, key) in data" :key="key" class="mb-2">
        <b-card class="h-100 shadow-sm">{{ value.body }}</b-card>
      </div>
    </template>

    <template v-slot:group_footer>group Footer</template>

    <template #empty_state>
      <h2>No Data Exists</h2>
    </template>

    <template v-slot:footer>
      <h1>Main footer</h1>
    </template>
  </ac-collection-grouped-accordion>
</template>

<script>
export default {
  data() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      show_pagination: true,
      total_count: 100,
      limit: 15,
      offset_key: "_start",
      limit_key: "_limit"
    };
  }
};
</script>

<style>
</style>
