<template>
  <ac-navbar
    :leftControls="lc"
    :rightControls="rc"
    :profiledata="rctwo"
    :searchOption="searchOption"
    :Designation="Designation"
    :Designationdata="Designationdata"
    :alignment="alignment"
    :imageicon="img"
    :profileiconposition="profileimage"
    :textcolor="textcolor"
    :bgcolor="bgcolor"
    :text="text"
    :text1="text1"
  />
</template>

<script>
export default {
  data() {
    return {
      Designation: true,
      Designationdata: "Marketing Manager",
      searchOption: true,
      text1:true,
      text:{
        x:"10",
        y:"25"
      },
      profileimage: "right",
      alignment: "right",
      textcolor:"dark",
      bgcolor:"bg-primary",
      img: "http://css.500apps.com/app-logos/trackly-logo.svg",
      lc: [
        {
          type: "link",
          label: "dashboard",
          icon: "Dashboard",
          link_to: "#alert"
        },
        {
          type: "link",
          label: "link",
          icon: "Reports",
          link_to: "#alert"
        }
      ],
      rc: [
        {
          type: "dropdown",
          icon: "fal fa-bell fa-lg p-2",
          items: [
            {
              type: "link",
              label: "Profile",
              link_to: "#",
              handler: "helper",
              handler_params: '{ "key1" : "value1" }'
            },
            {
              type: "link",
              label: "signout",
              link_to: "#",
              handler: "helper",
              handler_params: '{ "key1" : "value1" }'
            }
          ]
        },
        {
          type: "dropdown",
          icon: "fas fa-plus-square p-2 fa-lg",
          items: [
            {
              type: "link",
              label: "profile",
              link_to: "#"
            },
            {
              type: "link",
              label: "signout",
              link_to: "#"
            }
          ]
        }
      ],
      rctwo: [
        {
          type: "dropdown",
          icon: "fal fa-bell fa-lg p-2",
          img:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHJKfK1sdr4qhEYCEd9IfwLHR4UoN65Gd0sna_RlQucjDz3v2Z6A",
          name: "beni arisandi",
          label: "madan",
          items: [
            {
              type: "link",
              label: "profile",
              link_to: "#"
            },
            {
              type: "link",
              label: "signout",
              link_to: "#"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style>
</style>
