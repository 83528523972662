var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Group As Rows")]),_c('ac-collection-grouped',{ref:"ac_collection_grouped",attrs:{"url":_vm.collection.url,"method":_vm.collection.method,"params":_vm.collection.params,"loading_image":_vm.collection.loading_image,"sort_key":_vm.collection.sort_key,"ac_cursor":{},"group_key":"postId","show_default_group":true,"pagination_type":"loadMore","total_count":21,"limit":5,"limit_key":"_limit","offset_key":"_start","show_searchbar":true,"show_tooltip":true,"tooltip_message":"search here ..."},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Main Header")]},proxy:true},{key:"group_header",fn:function(ref){
var groupItem = ref.groupItem;
return [_c('h2',[_vm._v("Group "+_vm._s(groupItem.name))])]}},{key:"default_group",fn:function(){return [_c('div',{staticClass:"col-md-4 mb-3"},[_c('b-card',{staticClass:"h-100 shadow-sm"},[_vm._v("Default slot")])],1)]},proxy:true},{key:"body",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(value,key){return _c('div',{key:key,staticClass:"col-md-4 mb-3"},[_c('b-card',{staticClass:"h-100 shadow-sm"},[_vm._v(_vm._s(value.body))])],1)})}},{key:"emptyState",fn:function(){return [_c('div',{staticClass:"w-100 py-5 text-center"},[_c('img',{staticClass:"mb-3 d-inline-block",attrs:{"src":"https://cdn-500.500apps.com/Track.ly/no-archived-projects.svg","alt":"Empty Activity"}}),_c('h4',{staticClass:"text-center text-muted d-block"},[_vm._v("No Activity Recorded")])])]},proxy:true},{key:"group_footer",fn:function(){return [_vm._v("group Footer")]},proxy:true},{key:"footer",fn:function(){return [_vm._v("Main footer")]},proxy:true}])}),_c('hr'),_c('hr'),_c('h1',[_vm._v("Group As Cols")]),_c('ac-collection-grouped',{ref:"ac_collection_grouped",attrs:{"url":_vm.collection.url,"method":_vm.collection.method,"params":_vm.collection.params,"loading_image":_vm.collection.loading_image,"sort_key":_vm.collection.sort_key,"group_col":_vm.group_col,"ac_cursor":{},"cols_class":"col-md-3 col-md-offset-2","group_key":"postId","show_default_group":_vm.show_default_group},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Main Header")]},proxy:true},{key:"group_header",fn:function(ref){
var groupItem = ref.groupItem;
return [_c('h2',{staticClass:"break-word"},[_vm._v("Group "+_vm._s(groupItem.name))])]}},{key:"default_group",fn:function(){return [_c('div',{staticClass:"col-md-4 mb-3"},[_c('b-card',{staticClass:"h-100 shadow-sm"},[_vm._v("Default slot")])],1)]},proxy:true},{key:"body",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(value,key){return _c('div',{key:key},[_c('b-card',{staticClass:"h-100 shadow-sm"},[_vm._v(_vm._s(value.body))])],1)})}},{key:"group_footer",fn:function(){return [_vm._v("group Footer")]},proxy:true},{key:"footer",fn:function(){return [_vm._v("Main footer")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }