<template>
  <ac-list-group :url="url"></ac-list-group>
</template>
<script>
export default {
  data() {
    return {
      url: "https://api.myjson.com/bins/12y3kn"
    };
  }
};
</script>