<template>
  <ac-rule-builder :fields="demoFields" :filter="filter"></ac-rule-builder>
</template>

<script>
export default {
  data() {
    return {
      demoFields: [
        {
          name: "ID",
          label: "ID",
          operations: [["Is", "equals"]]
        },
        {
          name: "totalPayments",
          label: "Total Payments",
          operations: [["Greater Than", "gte"]],
          filterable: true,
          fields: [
            {
              name: "department",
              label: "Department",
              operations: [["Is", "equals"]]
            }
          ]
        }
      ],
      filter: {
        all: true,
        rules: [
          {
            field: null
          }
        ]
      }
    };
  }
};
</script>

<style>
</style>
