<template>
  <div>
    <h1>Group As Rows</h1>
    <ac-collection-grouped
      ref="ac_collection_grouped"
      :url="collection.url"
      :method="collection.method"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
      :ac_cursor="{}"
      group_key="postId"
      :show_default_group="true"
      pagination_type="loadMore"
      :total_count="21"
      :limit="5"
      limit_key="_limit"
      offset_key="_start"
      :show_searchbar="true"
      :show_tooltip="true"
      tooltip_message="search here ..."
    >
      <template v-slot:header>Main Header</template>

      <template v-slot:group_header="{ groupItem }">
        <h2>Group {{ groupItem.name }}</h2>
      </template>
      <template #default_group>
        <div class="col-md-4 mb-3">
          <b-card class="h-100 shadow-sm">Default slot</b-card>
        </div>
      </template>
      <template v-slot:body="{ data }" class="item">
        <div v-for="(value, key) in data" :key="key" class="col-md-4 mb-3">
          <b-card class="h-100 shadow-sm">{{ value.body }}</b-card>
        </div>
      </template>
      <template #emptyState>
        <div class="w-100 py-5 text-center">
          <img
            class="mb-3 d-inline-block"
            src="https://cdn-500.500apps.com/Track.ly/no-archived-projects.svg"
            alt="Empty Activity"
          />
          <h4 class="text-center text-muted d-block">No Activity Recorded</h4>
        </div>
      </template>
      <template v-slot:group_footer>group Footer</template>

      <template v-slot:footer>Main footer</template>
    </ac-collection-grouped>
    <hr />
    <hr />
    <h1>Group As Cols</h1>
    <ac-collection-grouped
      ref="ac_collection_grouped"
      :url="collection.url"
      :method="collection.method"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
      :group_col="group_col"
      :ac_cursor="{}"
      cols_class="col-md-3 col-md-offset-2"
      group_key="postId"
      :show_default_group="show_default_group"
    >
      <template v-slot:header>Main Header</template>

      <template v-slot:group_header="{ groupItem }">
        <h2 class="break-word">Group {{ groupItem.name }}</h2>
      </template>

      <template #default_group>
        <div class="col-md-4 mb-3">
          <b-card class="h-100 shadow-sm">Default slot</b-card>
        </div>
      </template>

      <template v-slot:body="{ data }" class="item">
        <div v-for="(value, key) in data" :key="key">
          <b-card class="h-100 shadow-sm">{{ value.body }}</b-card>
        </div>
      </template>

      <template v-slot:group_footer>group Footer</template>

      <template v-slot:footer>Main footer</template>
    </ac-collection-grouped>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      group_col: true,
      show_default_group: false
    };
  }
};
</script>

<style>
.break-word {
  word-break: break-all;
}
</style>
