<template>
  <div>
    <ac-slideout-layout rightclass="col-md-5" leftClass="col-md-7">
    <template v-slot:header>Main Header</template>
    <template v-slot:rightSide>rightside</template>
    <template v-slot:leftSide>leftside</template>
    <template v-slot:footer>
        <b-button variant="outline-secondary mr-2">Cancel</b-button>
                  <b-button variant="primary">Ok</b-button>
    </template>
    </ac-slideout-layout>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
     
    };
  },
};
</script>

<style>
</style>
