<template>
    <div>
              <ac-image
                src="http://via.placeholder.com/1200x768"
                thumb_src="http://via.placeholder.com/64x64"
                :slide="true"
                :fancybox="true"
                width="1200"
                height="768"
              />
            </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
