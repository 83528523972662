<template>
  <div>
    <ac-simple-wizard
      :steps="steps"
      :align_vertical="true"
      :previous_btn_icon="`fas fa-pencil-alt`"
      :previous_btn_class="previousButtonClass"
      :next_btn_label="nextButtonLabel"
      :next_btn_class="nextButtonClass"
      :finish_btn_label="finishButtonLabel"
      :finish_btn_class="finishButtonClass"
    ></ac-simple-wizard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      previousButtonLabel: "Previous",
      previousButtonClass: "btn btn-primary",
      nextButtonLabel: "Next",
      nextButtonClass: "btn btn-primary",
      finishButtonLabel: "Completed",
      finishButtonClass: "btn btn-success",
      steps: [
        {
          html: '<div class="header-circle">1</div>',
          title: "step 1",
          description: "step 1 description",
          helper: "ac-radio",
          helper_reference: "step1_ref",
          active: true,
          completed: false
        },

        {
          html: '<div class="header-circle">2</div>',
          title: "step 2",
          description: "step 2 description",
          helper: "ac-input",
          helper_reference: "step2_ref",
          active: true,
          completed: false
        },

        {
          html: '<div class="header-circle">3</div>',
          title: "step 3",
          description: "step 3 description",
          helper: "ac-checkbox",
          helper_reference: "step3_ref",
          active: true,
          completed: false
        }
      ]
    };
  }
};
</script>

<style>
.header-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkblue;
  color: white;
}
</style>
