<template>
  <div>
    <button  v-b-tooltip.hover title="My button" id="AA">dynamiclick1</button>
    <button v-b-tooltip.hover title="All button" id="BB">dynamiclick2</button>
    <ac-dynamic-view refs="example" :custom_buttons="false" :config="config"></ac-dynamic-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: [
        {
          label: "input",
          icon: "fal fa-table",
          button_id: "AA",
          helper: "ac-input",
          tooltip:"Button one",
        },
        {
          label: "radio",
          icon: "fal fa-th",
          button_id: "BB",
          helper: "ac-radio",
          tooltip:"Button two",
        }
      ]
    };
  }
};
</script>

<style>
</style>
