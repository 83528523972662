<template>
  <ac-simple-label :data="data_send">
    <template v-slot:title="{ data }">
      {{data}}
    </template>
  </ac-simple-label>
</template>
<script>
export default {
  data: function() {
    return {
      data_send: [
        { label: "Authentication", href: "#/auth" },
        { label: "Registration", href: "#/registration" },
        { label: "Login", href: "#/login" },
        { label: "Uncategorised", href: "#/uncategorised" },
        { label: "Generic SQL", href: "#/generic" }
      ]
    };
  }
};
</script>