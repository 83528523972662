<template>
  <div>
    <ac-form
      :data="formData"
      fetch_url
      post_url="https://google.com"
      params
      save_submit_route
      loading_image
    >
      <b-button type="submit">Save</b-button>
    </ac-form>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      formData: [
        {
          tag: "ac-input",
          type: "text",
          value: [],
          label: "Email",
          multi: true,
          ac_type: "Array",
          children: [],
          max: 3,
          class: "col-md-3",
          name: "email",
          required: true
        },
        {
          tag: "ac-input",
          type: "password",
          ac_type: "Object",
          children: {},
          value: "123",
          label: "Password",
          multi: true,
          class: "col-md-3",
          name: "password"
        },
        {
          tag: "ac-select",
          type: "search",
          ac_type: "Object",
          children: {},
          options: [{text: '1', value: 1}, {text: '2', value: 2}],
          value: 2,
          label: "search",
          multi: false,
          name: "search",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "url",
          ac_type: "array",
          children: {},
          value: "",
          label: "url",
          multi: false,
          name: "url",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "tel",
          ac_type: "array",
          children: {},
          value: "",
          label: "tel",
          multi: false,
          name: "tel",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "number",
          ac_type: "array",
          children: {},
          value: "",
          label: "number",
          multi: false,
          name: "number",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "range",
          ac_type: "array",
          children: {},
          value: "",
          label: "range",
          multi: false,
          name: "range",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "date",
          ac_type: "array",
          children: {},
          value: "",
          label: "date",
          multi: false,
          name: "date",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "month",
          ac_type: "array",
          children: {},
          value: "",
          label: "month",
          multi: false,
          name: "month",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "week",
          ac_type: "array",
          children: {},
          value: "",
          label: "week",
          multi: false,
          name: "week",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "time",
          ac_type: "array",
          children: {},
          value: "",
          label: "time",
          multi: false,
          name: "time",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "datetime-local",
          ac_type: "array",
          children: {},
          value: "",
          label: "datetime-local",
          multi: false,
          name: "datetime-local",
          class: "mt-2"
        },
        {
          tag: "ac-input",
          type: "color",
          ac_type: "array",
          children: {},
          value: "",
          label: "color",
          multi: false,
          name: "color",
          class: "mt-2"
        },
        {
          tag: "ac-radio",
          type: "radio",
          ac_type: "array",
          children: {},
          value: "orange",
          label: "radio",
          multi: false,
          name: "orange",
          inner_text: "check this",
          checked: true,
          class: "mt-2"
        },
        {
          tag: "ac-checkbox",
          type: "checkbox",
          ac_type: "array",
          children: {},
          value: "orange",
          label: "checkbox",
          multi: false,
          name: "checkbox",
          checked: true,
          inner_text: "checkbox",
          class: "mt-2"
        },
        {
          tag: "ac-select",
          "select-size": 4,
          options: [
            { value: null, text: "Please select some item" },
            { value: "a", text: "This is option a" },
            { value: "b", text: "Default Selected Option b" },
            { value: "c", text: "This is option c" },
            { value: "d", text: "This one is disabled", disabled: true },
            { value: "e", text: "This is option e" },
            { value: "e", text: "This is option f" }
          ],

          ac_type: "array",
          children: {},
          value: "orange",
          label: "select",
          multi: false,
          name: "select",
          checked: true
        },
        {
          tag: "ac-multiselect",
          options: [
            "Select option",
            "options",
            "selected",
            "mulitple",
            "label",
            "searchable",
            "clearOnSelect",
            "hideSelected",
            "maxHeight",
            "allowEmpty",
            "showLabels",
            "onChange",
            "touched"
          ],
          ac_type: "array",
          children: {},
          value: "",
          searchable: false,
          "close-on-select": false,
          "show-labels": false,
          placeholder: "Pick a value",
          label: "radio",
          multi: false,
          name: "orange",

          checked: true
        },
        {
          tag: "ac-text-area",
          id: "textarea",
          ac_type: "array",
          children: {},
          placeholder: "Enter something...",
          label: "radio",
          rows: 3,
          "max-rows": 6,
          multi: false,
          name: "orange",
          checked: true
        },
        {
          tag: "ac-file",
          ac_type: "array",
          children: {},
          placeholder: "Enter something...",
          label: "file",
          "drop-placeholder": "Drop file here...",
          state: "Boolean(file)",
          rows: 3,
          "max-rows": 6,
          multi: false,
          name: "orange",
          checked: true
        },
      ]
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
