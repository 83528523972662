<template>
  <ac-material-dropdown
    :button_options="dropdown.button_options"
    @item_click="event_test"
    :show_icon=true
    url="https://api.myjson.com/bins/lvaxd"
  />
</template>

<script>
export default {
  data: function() {
    return {
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt"
          }
        ],
        button_options: {
          variant: "bg-transparent",
          // icon:"fas fa-pencil-alt",
          size: "sm",
          right: true,
          label: "testing"
        }
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
