<template>
  <div>
    <ac-collection-grouped-table
      ref="acCollectiontable"
      :columns="table_data.columns"
      :dropdown="table_data.dropdown"
      :dropdown_options="dropdown.options"
      :dropdown_button_options="dropdown.button_options"
      :show_dropdown_icons="true"
      :bulk="table_data.bulk"
      :url="collection.url"
      :method="collection.method"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
      :ac_cursor="collection.ac_cursor"
      @dropdown_click="event_test"
      group_key="postId"
      pagination_type="loadMore"
      load_more_type="loadMoreButton"
      :total_count="20"
      :limit="5"
      limit_key="_limit"
      offset_key="_start"
      :enable_drag="enable_drag"
      @on-drag="onDragCheck"
    >
      <template v-slot:group_header="{ groupItem }">
        <h6>Group {{ groupItem.email }}</h6>
      </template>
      <template v-slot:header>Main Header</template>
      <template v-slot:footer>Main footer</template>
      <template #empty_state>
        <p>
          <center>No Data</center>
        </p>
      </template>
    </ac-collection-grouped-table>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      enable_drag: true,
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {},
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      card_links: [
        {
          label: "First",
          href: "/first",
          variant: "primary"
        },
        {
          label: "Second",
          href: "/second",
          variant: "success"
        }
      ],
      table_data: {
        bulk: true,
        dropdown: true,
        columns: [
          {
            key: "checkbox",
            class: "w-10"
          },
          {
            label: "Id",
            key: "id",
            class: "w-50"
          },
          {
            label: "Email",
            key: "email",
            class: "w-25"
          },
          {
            key: "options",
            class: "w-10"
          }
        ]
      },
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt",
            handler: "hello"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt",
            handler: "hello"
          }
        ],
        button_options: {
          icon: "fal fa-ellipsis-h",
          variant: "outline-secondary",
          size: "sm",
          right: true
        }
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    },
    onDragCheck(testd, dragged_ids) {
      console.log("testd..", testd, dragged_ids);
    }
  }
};
</script>

<style>
</style>
