<template>
  <ac-collection-tableone :rows="rows" :checkbox="checkbox" :number="number">
  </ac-collection-tableone>
</template>

<script>
export default {
data(){
  return{
    checkbox: false,
    number: true,
    rows: [
      {name: "Chandler Bing", phone: '305-917-1301', profession: 'IT Manager', Extension: 'senior manager'},
      {name: "Ross Geller", phone: '210-684-8953', profession: 'Paleontologist', Extension: 'senior manager'},
      {name: "Rachel Green", phone: '765-338-0312', profession: 'Waitress', Extension: 'senior manager'},
      {name: "Monica Geller", phone: '714-541-3336', profession: 'Head Chef', Extension: 'senior manager' },
      {name: "Joey Tribbiani", phone: '972-297-6037', profession: 'Actor', Extension: 'senior manager' },
      {name: "Phoebe Buffay", phone: '760-318-8376', profession: 'Masseuse', Extension: 'senior manager'},
      {name: "Phoebe Buffay", phone: '760-318-8376', profession: 'Masseuse', Extension: 'senior manager'}
    ]
  }
},
}
</script>

<style>

</style>
