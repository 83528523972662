<template>
  <b-card>
    <ac-simple-automation :rule_json="ruleJson"  v-model="ruleBuilderJson"></ac-simple-automation>
  </b-card>
</template>
<script>
export default {
  data() {
    return {
        triggerModel:"",
        ruleBuilderJson:[],
        modelf:{},
       ruleJson:{
                    condition: "Or",
                    selectedKey: "",
                    selectedOperator: "",
                    selectedVal: "",
                    keys: {
                        url: "https://api.myjson.com/bins/p3vws",
                        keyvalue: "id",
                        keytext: "name",
                        keytype: "number",
                        options: []
                    },
                    operators: {
                        "text": [{ text: "EqualsSelect", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                        "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                        "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }, { text: "Greater Than", value: "GT" }],
                        "select": [{ text: "EqualsSelect", value: "EQ" }, { text: "Not Equals slecte", value: "NE" }]
                    },
                    controls: {
                        "text": { type: "text" },
                        "date": {
                            type: "appup-datetime",
                            name: "date",
                            input: "date",
                            label: "Date Picker",
                            placeholder: "Date here",
                            custom: {
                                "dateFormat": "m-Y-d"
                            }
                        },
                         "number": { type: "number" },
                         "select": { type: "select", url: "https://api.myjson.com/bins/p3vws", keyvalue: "id", keytext: "name", options: [] }
                    }
                },
                //  prefillData: [
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "Or",
                //                     "selectedKey": "AX",
                //                     "selectedOperator": "EQ",
                //                     "selectedVal": "EA",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "select",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "And"
                //         },
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "And",
                //                     "selectedKey": "",
                //                     "selectedOperator": "",
                //                     "selectedVal": "",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "date",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "Or"
                //         },
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "Or",
                //                     "selectedKey": "",
                //                     "selectedOperator": "",
                //                     "selectedVal": "",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "date",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "Or"
                //         },
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "Or",
                //                     "selectedKey": "",
                //                     "selectedOperator": "",
                //                     "selectedVal": "",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "date",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "Or"
                //         },
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "Or",
                //                     "selectedKey": "",
                //                     "selectedOperator": "",
                //                     "selectedVal": "",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "date",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "Or"
                //         },
                //         {
                //             "rules": [
                //                 {
                //                     "condition": "Or",
                //                     "selectedKey": "",
                //                     "selectedOperator": "",
                //                     "selectedVal": "",
                //                     keys: {
                //                         url: "https://restcountries.eu/rest/v2/all",
                //                         keyvalue: "alpha2Code",
                //                         keytext: "name",
                //                         keytype: "date",
                //                         options: []
                //                     },
                //                     operators: {
                //                         "text": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "date": [{ text: "Equal", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "number": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }],
                //                         "select": [{ text: "Equals", value: "EQ" }, { text: "Not Equals", value: "NE" }]
                //                     },
                //                     controls: {
                //                         "text": { type: "text" },
                //                         "date": { type: "date" },
                //                         "number": { type: "number" },
                //                         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                //                     }
                //                 }
                //             ],
                //             "condition": "Or"
                //         }
                //     ]
    }
  },
}
</script>