<template>
  <div>
    <ac-collection-table
      ref="acCollectiontable"
      :columns="table_data.columns"
      :option_label="table_data.option"
      :dropdown="table_data.dropdown"
      :dropdown_options="dropdown.options"
      :dropdown_button_options="dropdown.button_options"
      :bulk="table_data.bulk"
      :url="collection.url"
      :method="collection.method"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
      :ac_cursor="collection.ac_cursor"
      :show_searchbar="show_searchbar"
      :search_key="search_key"
      pagination_type="pagination"
      :limit="limit"
      :offset_key="offset_key"
      :limit_key="limit_key"
      :is_custom_header="true"
      :show_dropdown_icons="true"
      searchbar_placeholder="Search here"
      preload="test"
      @dropdown_click="event_test"
      @ctbulkaction="event_test"
      @checkbox_clicked="event_test"
      :scroll_style="scrollClass"
      :data_count_url="collection.count_url"
      :count_key="collection.count_key"
    >
      <template #empty_state>
        <p>
          <center>No Data</center>
        </p>
      </template>
      <!-- <template #caption="data">Loaded {{data.loaded_data_count}} of {{total_count}}</template> -->
      <template v-slot:caption="data_count">Loaded {{data_count.loaded_data_count}} of {{data_count.total_count}}</template>
      <template v-slot:header>Main Header</template>
      <template v-slot:footer>Main footer</template>
      <template v-slot:actions_slot>action slot</template>
      <!-- <template #option="{ data }">
      {{data.item.name}}
      </template>-->
    </ac-collection-table>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {},
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name",
        count_url: "https://api.myjson.com/bins/dxwm4",
        count_key: "count"
      },
      card_links: [
        {
          label: "First",
          href: "/first",
          variant: "primary"
        },
        {
          label: "Second",
          href: "/second",
          variant: "success"
        }
      ],
      table_data: {
        bulk: true,
        dropdown: true,
        option: "options",
        columns: [
          {
            label: "Id",
            sortable: true,
            key: "id"
          },
          {
            label: "Name",
            sortable: false,
            key: "name"
          },
          {
            label: "Email",
            sortable: false,
            key: "email"
          }
        ]
      },
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt",
            handler: "hello"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt",
            handler: "hello"
          }
        ],
        button_options: {
          icon: "fal fa-ellipsis-h",
          variant: "bg-transparent p-0",
          size: "lg",
          right: true
        },
        tabledata: {}
      },
      search_key: "name",
      show_searchbar: true,
      show_pagination: true,
      total_count: 40,
      limit: 10,
      offset_key: "_start",
      limit_key: "_limit",
      scrollClass: {"over-flow" : "auto","max-height" : "250px"}
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  },
  mounted() {
    //this.tabledata = this.$refs.acCollectiontable.data;
  }
};
</script>

<style>
</style>
