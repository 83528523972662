<template>
  <div>
    {{dateRange}}
    <ac-date-range-picker
      :opens="opens"
      :min_date="minDate"
      :max_date="maxDate"
      single_date_picker="false"
      show_week_numbers="true"
      :show_dropdowns="showDropdowns"
      auto_apply="false"
      v-model="dateRange"
      :ranges="ranges"
      show_ranges="true"
      time_picker="true"
      time_picker_24_hour="true"
      :time_picker_increment="3"
      time_picker_seconds="true"
      date_format="YYYY-MM-DD"
      tooltip_text="Hi I am tooltip"
    >
      <div
        slot="input"
        slot-scope="picker"
        style="min-width: 350px;"
      >{{ picker.startDate | date }} - {{ picker.endDate | date }}</div>
    </ac-date-range-picker>
  </div>
</template>

<script>
export default {
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    }
  },
  data() {
    return {
      opens: "center",
      ranges: {
        Today: ["2019-09-18T16:42:53+05:30","2019-09-18T16:42:53+05:30"]
      },
      minDate: "2016-09-02",
      maxDate: "2019-10-02",
      dateRange: {
        startDate: "2019-05-01",
        endDate: "2019-05-10"
      },
      show_ranges: true,
      singleDatePicker: false,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: true,
      autoApply: false,
      showWeekNumbers: true,
    };
  }
};
</script>

