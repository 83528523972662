<template>
  <ac-signature-pad id="signature" save_type="image/jpeg" @save="save_click($event)"></ac-signature-pad>
</template>

<script>
export default {
  methods: {
    save_click(e) {
      console.log(e);
    }
  }
};
</script>