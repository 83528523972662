<template>
  <div>
    <ac-image-picker
      :data="data"
      upload_url="https://jsonplaceholder.typicode.com/posts"
      search_key="name"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          label: 'images',
          categories: [
            {
              label: 'my images',
              files: [],
            },
            {
              label: 'free images',
              files: [],
            },
          ],
        },
        {
          label: 'videos',
          categories: [
            {
              label: 'my videos',
              files: [],
            },
            {
              label: 'free videos',
              files: [],
            },
          ],
        },
      ],
    }
  },
}
</script>
