<template>
  <ac-button-group :data="d" @click="clcked"></ac-button-group>
</template>

<script>
export default {
  data: function() {
    return {
      d: [
        {
          key: "fome_ref",
          name: "Home",
          type: "",
          variant:"success",
          __slot__: "<div>helo</div>"
        },
        {
          name: "Homeee",
          type: "",
          key: "home",
          __slot__: "<div>home slot</div>",
          variant: ""
        },
        {
          name: "Sales",
          label: "Sales",
          type: "dropdown",
          variant: "primary",
          items: [
            {
              name: "Home",
              label: "Home",
              url: "#Home"
            },
            {
              name: "Sales",
              url: "/sales",
              iconClass: "fa fa-envelope"
            },
            {
              name: "Sales",
              type: "input",
              iconClass: "fa fa-envelope",
              oninputchange: "raiseevent"
            }
          ]
        },
        {
          name: "Home",
          url: "#/examples"
        },
        {
          name: "Sales",
          url: "#/examples/ac-button-group",
          iconClass: "fa fa-envelope"
        },
        {
          name: "Developers",
          variant: "success",
          type: "dropdown",

          items: [
            {
              name: "java",
              label: "Home",
              url: "#java"
            },
            {
              name: "Angular"
            },
            {
              name: "vue"
            }
          ]
        }
      ],
      clcked: function(data) {
        console.log("i am actionable", data);
      }
    };
  }
};
</script>

