<template>
  <div>
    <ac-message :starred="star">
      <template slot="message_user_name">
        <h6 class="mb-0 d-inline-block font-weight-bold">Rawibabbu</h6>
      </template>

      <template slot="message_time">
        <small class="text-muted font-weight-lighter">12:42 PM</small>
      </template>

      <template slot="message_toolbar">
        <div role="group" aria-label="Basic example" class="btn-group btn-group-sm bg-white">
          <div class="btn-group b-dropdown dropdown">
            <button
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
              class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-no-caret"
            >
              <img
                src="https://image.flaticon.com/icons/svg/187/187154.svg"
                alt="Smiley face"
                height="15"
                width="15"
                title
                data-original-title="Reactions"
              />
            </button>
            <ul role="menu" tabindex="-1" class="dropdown-menu">
              <li title data-original-title="Hearts">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187154.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
              <li title data-original-title="Laugh">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187134.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
              <li title data-original-title="Very happy">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187130.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
              <li title data-original-title="Wink">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187131.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
              <li title data-original-title="Wierd">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187152.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
              <li title data-original-title="Sad">
                <a role="menuitem" target="_self" href="#" class="dropdown-item">
                  <img
                    src="https://image.flaticon.com/icons/svg/187/187143.svg"
                    alt="Smiley face"
                    height="15"
                    width="15"
                  />
                </a>
              </li>
            </ul>
          </div>
          <button
            type="button"
            title
            class="btn btn-outline-secondary"
            data-original-title="Tagged Thread"
          >
            <span>
              <i class="fal fa-reply fa-md"></i>
            </span>
          </button>
          <button
            type="button"
            title
            class="btn btn-outline-secondary"
            data-original-title="Reply as a thread"
          >
            <span>
              <i class="fal fa-comment-lines fa-md"></i>
            </span>
          </button>
          <button
            type="button"
            title
            class="btn btn-outline-secondary"
            data-original-title="Forward message"
          >
            <span>
              <i class="fal fa-share fa-md"></i>
            </span>
          </button>
          <button type="button" title class="btn btn-outline-secondary" data-original-title="Star">
            <span>
              <i class="fal fa-star fa-md"></i>
            </span>
          </button>
          <!---->
          <div class="p-0 btn-group b-dropdown dropdown" title data-original-title="More options">
            <!---->
            <button
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
              class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-no-caret"
            >
              <i class="fal fa-ellipsis-h fa-md"></i>
            </button>
            <ul role="menu" tabindex="-1" class="dropdown-menu">
              <li>
                <a role="menuitem" target="_self" href="#" class="dropdown-item">Copy</a>
              </li>
              <li>
                <a
                  role="menuitem"
                  target="_self"
                  href="#"
                  class="dropdown-item"
                >Remind me about this</a>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template slot="message_reactions">
        <div class="mt-2">
          <span
            title
            data-placement="right"
            data-original-title="Sumalatha"
            class="btn btn-outline-secondary btn-sm py-0 mr-1 rounded-pill"
          >
            <img
              src="https://image.flaticon.com/icons/svg/187/187154.svg"
              alt="Smiley face"
              height="13"
              width="12"
            />
            <small class="ml-1 text-muted count">1</small>
          </span>
          <span
            title
            data-placement="right"
            data-original-title="Comment"
            class="btn btn-outline-secondary btn-sm py-0 mr-1 rounded-pill"
          >
            <img
              src="https://image.flaticon.com/icons/svg/187/187134.svg"
              alt="Smiley face"
              height="13"
              width="12"
            />
            <small class="ml-1 text-muted count">1</small>
          </span>
          <span
            title
            data-placement="right"
            data-original-title="Nani"
            class="btn btn-outline-secondary btn-sm py-0 mr-1 rounded-pill"
          >
            <img
              src="https://image.flaticon.com/icons/svg/187/187130.svg"
              alt="Smiley face"
              height="13"
              width="12"
            />
            <small class="ml-1 text-muted count">1</small>
          </span>
        </div>
      </template>

      <template slot="message">
        <p class="mb-0 font-weight-light">
          Cras sit amet nibh libero, in gravida nulla. Nulla
          vel metus
        </p>
      </template>

      <template slot="message_avatar">
        <img
          class="mr-2 rounded"
          width="39"
          height="39"
          src="http://media1.santabanta.com/full1/Creative/Abstract/abstract-310v.jpg"
          alt="user pic"
        />
      </template>

      <template slot="message_threads">
        <div class="media thread-wrap custom-thread-image position-relative mt-2">
          <div class="media-body d-md-flex align-items-center">
            <div class="mb-2 mb-md-0 mr-3 collage">
              <span title class="pr-4 replies-img" data-original-title="Mounika.velidi">
                <div class="ac-gravatar">
                  <img
                    src="//www.gravatar.com/avatar/fb8fbcd6f36305aa614b6315f5606e1a?s=30&amp;d=retro&amp;r=g"
                    alt="Avatar"
                    class="rounded"
                  />
                </div>
              </span>
              <!---->
            </div>
            <div class="position-relative">
              <p class="my-0 small">
                Thread:
                <a>
                  1
                  replies
                </a>
              </p>
            </div>
          </div>
        </div>
      </template>
    </ac-message>
  </div>
</template>

<script>
export default {
  data() {
    return {
      star: false
    };
  }
};
</script>