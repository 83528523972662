<template>
    <div>
        <ac-bulk-actions
            :buttons="bulk_options"
            :collection_ref="bulk_ref"
            class="mb-2"
          />

          <ac-collection
              ref="ac_collection_bulk"
              :url="collection.url"
              :method="collection.method"
              :ac_cursor="collection.ac_cursor"
              :params="collection.params"
              :loading_image="collection.loading_image"
              :sort_key="collection.sort_key"
            >
              <template #header>
                <header class="border p-2 mb-2">
                  Ac collection header
                </header>
              </template>

              <template #body="{ data }">
                <b-row>
                  <b-col
                    v-for="item in data"
                    :key="item.id"
                    class="ac__item"
                    :lg="6"
                  >
                     <b-card
                      :title="item.name"
                      class="mb-2"
                    >
                      <b-card-text> {{ item.body }}</b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>

              <template #footer>
                <footer class="border p-2 mt-2">
                  ac collection footer
                </footer>
              </template>
            </ac-collection>
    </div>
</template>

<script>
export default {
  data: function () {
      return {
          ac_sort_collection: {},
                bulk_options: [
        {
          label: 'Send Mail',
          icon: '',
          url: '/bulk/sendmail',
          method: 'post',
        },
        {
          label: 'Add tags',
          handler: 'set_ajax',
        },
        {
          label: 'Delete',
          type: 'delete',
          message: 'Are you sure ?',
          handler: 'set_ajax',
        },
      ],
      bulk_ref: {},
       collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      
      }
  },
  mounted () {
         
    this.$refs.ac_collection_bulk.set_ajax()
    this.bulk_ref = this.$refs.ac_collection_bulk
      }
}
</script>

<style>

</style>
