<template>
  <ac-grid
    :grid_items="grid_items"
    :enable_drag="grid_options.enable_drag"
    :resizable="grid_options.resizable"
    :auto_size="grid_options.auto_size"
    :grid_width="grid_options.grid_width"
    :grid_height="grid_options.grid_height"
    :grid_column="grid_options.grid_column"
    @layout_updated_event="event_test"
    @clicked_event="event_test"
  />
</template>

<script>
export default {
  data: function() {
    return {
      grid_items: [
        {
          label: "Item 1",
          description: "Desription 1",
          helper:"ac-radio",
          id: 1,
          position: {
            x: 0,
            y: 0
          }
        },
        {
          label: "Item 2",
          description: "Desription 2",
          helper:"ac-input",
          id: 2,
          position: {
            x: 2,
            y: 0
          },
          width: 2,
          height: 1
        }
      ],
      grid_options: {
        enable_drag: true,
        resizable: true,
        auto_size: false,
        grid_width: 2,
        grid_height: 1,
        grid_column: 12
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
