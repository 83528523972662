<template>
    <ac-dropdown :split="split" :items="items" @d_item_clicked="dropdownClick" :dropdown_header="dropdownHeader"></ac-dropdown>
</template>

<script>
export default {
    data(){
        return {
            items:[{label:"name",link:"#/name"},{label:"name",link:"#/name"}],
            dropdownHeader: "testing",
            split: false
        }
    },
    methods:{
     dropdownClick: function(data){
         debugger;
        
     }
    }
}
</script>

<style>

</style>
