var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',[_vm._v("With data prop")]),_c('ac-tab',{staticClass:"pb-3",attrs:{"data":_vm.tabs_data.data,"align":_vm.tabs_data.align,"tabs_attrs":{ 'card': true, pills: true }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Main Header")]},proxy:true},{key:"title",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.label))]}},{key:"tab_body",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.content))]}},{key:"tab_ref_1",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(_vm._s(data.body))])]}},{key:"tab_ref_2",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-3 p-2 align-items-center"},[_vm._v(_vm._s(data.body))]),_c('ac-material-dropdown',{attrs:{"options":_vm.dropdown.options,"button_options":_vm.dropdown.button_options},on:{"item_click":_vm.event_test}})],1)]}},{key:"footer",fn:function(){return [_vm._v("Main footer")]},proxy:true}])},[_c('template',{slot:"tabs"},[_c('b-nav-item',{attrs:{"href":"#"}},[_vm._v("Custom tab")])],1)],2),_c('h6',[_vm._v("With url prop")]),_c('ac-tab',{attrs:{"align":_vm.tabs_url.align,"tabs_attrs":{ 'card': true, pills: true },"url":_vm.tabs_url.url},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Main Header")]},proxy:true},{key:"title",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.label))]}},{key:"tab_body",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.content))]}},{key:"footer",fn:function(){return [_vm._v("Main footer")]},proxy:true}])},[_c('template',{slot:"tabs"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }