<template>
<div>    
  <!-- <b-button @click="show">show modal</b-button> -->
   <ac-modal block="ac-checkbox"></ac-modal>
   </div>
</template>

<script>
// import func from '../vue-temp/vue-editor-bridge';
export default {
  methods:{
      "show":function(){
          this.$children[0].$refs.myModalref.show();
          //this.$refs.test.show();
      }
  }
}
</script>

<style>

</style>
