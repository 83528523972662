<template>
  <ac-traclytable :theader="theader"
            :checkbox="checkbox"
            :numbers="numbers"
            :items="items"
            
            
            />
</template>

<script>
import acTraclytable from "../components/ac-traclytable.vue";
export default {
  data() {
    return {
     
      checkbox: false,
      numbers: false,
    
        
      theader: [
        { tableheader: "Day" },
        { tableheader: "Project" },
        { tableheader: "Duration" },
        
      ],

      items: [
        {
          age: "Tue  (Jul 11, 2019)",
          badges:"badge rounded text-white mr-1 text-uppercase bg-success",
          badge_name:"u",
          first_name: "UX Team",
          date: "00:44  Hrs",
           icon:'fal fa-plus fa-sm ml-2',
          
        },
        {
          age: "",
          badges:"badge rounded text-white mr-1 text-uppercase bg-success",
          badge_name:"",
          first_name: "500apps Design",
          date: "00:01  Hrs",
           icon:'fal fa-plus fa-sm ml-2'
         
        },
        {
          age: "Tue  (Jul 9, 2019)-5",
         badges:"badge rounded text-white mr-1 text-uppercase bg-success",
          badge_name:"u",
          first_name: "CRM",
          date: "00:00  Hrs",
           icon:'fal fa-plus fa-sm ml-2',
         
        },
        {
          age: "Tue  (Jul 8, 2019)-5",
         badges:"badge rounded text-white mr-1 text-uppercase bg-success",
          badge_name:"u",
          first_name: "CRM",
          date: "00:03 Hrs",
           icon:'fal fa-plus fa-sm ml-2'
         
        },
         {
          age: "",
         badges:"badge rounded text-white mr-1 text-uppercase bg-primary",
          badge_name:"u",
          first_name: "Track",
          date: "00:03 Hrs",
           icon:'fal fa-plus fa-sm ml-2'
         
        },
        {
          age: "",
          badges:"badge rounded text-white mr-1 text-uppercase bg-primary",
          badge_name:"u",
          first_name: "Newsletter",
          date: "00:01 Hrs",
           icon:'fal fa-plus fa-sm ml-2'
         
        },

        //   { tabledata1:[{age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }]},
        //   { tabledata2:[{age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }]},
      ]
    };
  }
};
</script>

<style>
</style>
