<template>
  <ac-header
    :left_controls="lc"
    :right_controls="rc"
    :title="title"
    navbgcolor="info"
    onloadActiveItem="Dashboard"
    text_color="light"
  >
  <!-- remove onloadActiveItem -->
    <template #right_component>
      <i class="fal fa-user">Right slot</i>
    </template>
    <template #left_component>
      <i class="fal fa-user">Left slot</i>
    </template>
    <template #leftend_component>
      <i class="fal fa-user">Left end slot</i>
    </template>
    <template #rightend_component>
      <i class="fal fa-user">Right end slot</i>
    </template>
  </ac-header>
</template>
​
<script>
export default {
  data() {
    return {
      title: {
        image: "http://css.500apps.com/app-logos/trackly-logo.svg",
        link_to: "#/dashboard"
      },
      rc: [
        {
          type: "link",
          icon: "fal fa-arrow-circle-down",
          link_to: "#/downloads"
        },

        {
          type: "dropdown",
          label: "Madan",
          opens: "left",
          activeWhen:['/examples','/examples/ac-header'],
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHJKfK1sdr4qhEYCEd9IfwLHR4UoN65Gd0sna_RlQucjDz3v2Z6A",
          items: [
            {
              type: "link",
              label: "Profile",
              link_to: "#",
              handler: "helper",
              handler_params: '{ "key1" : "value1" }'
            },
            {
              type: "link",
              label: "Signout",
              link_to: "#",
              activeWhen:['/examples/ac-header']
            }
          ]
        },
        {
          type: "dropdown",
          label: "Madan",
          icon: "fal fa-trash-alt",
          items: [
            {
              type: "link",
              label: "Profile",
              link_to: "#",
              handler: "helper",
              handler_params: '{ "key1" : "value1" }'
            },
            {
              type: "link",
              label: "Signout",
              link_to: "#"
            }
          ]
        }
      ],
      lc: [
        {
          type: "dropdown",
          label: "Madan",
          opens: "right",
          activeWhen:['/examples','/examples/ac-header'],
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHJKfK1sdr4qhEYCEd9IfwLHR4UoN65Gd0sna_RlQucjDz3v2Z6A",
          items: [
            {
              type: "link",
              label: "Profile",
              link_to: "#",
              handler: "helper",
              handler_params: '{ "key1" : "value1" }'
            },
            {
              type: "link",
              label: "Signout",
              link_to: "#",
              activeWhen:['/examples/ac-header']
            }
          ]
        },
        {
          type: "link",
          label: "Dashboard",
          icon: "Dashboard",
          link_to: "#alert",
          activeWhen: ["/dashboard"]
        },
        {
          type: "link",
          label: "Pages",
          icon: "pages",
          link_to: "#alert",
          activeWhen: ["/pages"]
        },
        {
          type: "link",
          label: "Reports",
          icon: "Reports",
          link_to: "#alert",
          activeWhen: ["/Reports"]
        },
        {
          type: "link",
          label: "Blocks",
          icon: "dashboard",
          link_to: "#code",
          activeWhen: ["/Blocks"]
        }
      ]
    };
  }
};
</script>
​
<style>
</style>
