<template>
  <div>
    <h1>Default type</h1>

    <!-- Default view of ac-resource -->
    <ac-resource
      :data="json"
      :upload_config="config_upload"
      group_key="category"
      @add_click="add_click"
      @grid_click="grid_click"
      @input="input_resource"
    />

    <h1>POPUP type</h1>

    <!-- Popup view of ac-resource -->
    <ac-resource
      :upload_config="config_upload"
      :data="json"
      ref="ac_resource_gallery_popup"
      src="https://api.myjson.com/bins/icwji"
      type="popup"
      group_key="category"
      @add_click="add_click"
      @grid_click="grid_click"
      @input="input_resource"
    />

     <b-button variant="primary"
              class="mt-1"
              @click="show_popup_gallery"
    >
      Show popup gallery
    </b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config_upload: {
        category: "Upload",
        props: {
          s3_config: {
            url: "https://qa.appup.com/publish/generate-presigned-url/user_profile?file_name=",
            id: "file_id",
            name: "file_name"
          },
          uploaders: [
            {
              name: "local",
            },
            {
              name: "google-drive",
              props: {
                client_id: "73951855780-t7e3dtbgkn1c1i0aig3baaurqlebe0fj.apps.googleusercontent.com",
                developer_key: "AIzaSyAihNgwrN9r7xo7sb4WOjK5CncESrDVcSM",
                actual_api: "https://apis.google.com/js/api.js?onload=onApiLoad"
              }
            },
            {
              name: "onedrive",
              props: {
                actual_api: "https://js.live.net/v7.0/OneDrive.js",
                one_drive_application_id: "bbd77aee-70a3-4f31-b948-9aca6849baa6"
              }
            },
            {
              name: "dropbox",
              props: {
                id: "dropboxjs",
                data_app_key: "u57gbt7nm11nfsp",
                actual_api: "https://www.dropbox.com/static/api/2/dropins.js"
              }
            }
          ]
        }
      },
      json: [
        {
          label: "Photo 1",
          src: "media/link.com",
          thumbnail: "https://klike.net/uploads/posts/2019-01/1547365376_1.jpg",
          category: "Photos"
        },
        {
          label: "Photo 2",
          src: "media/link.com",
          thumbnail:
            "https://klike.net/uploads/posts/2019-01/medium/1547365386_2.jpg",
          category: "Photos"
        },
        {
          label: "Photo 3",
          src: "media/link.com",
          thumbnail:
            "https://klike.net/uploads/posts/2019-01/medium/1547365358_3.jpg",
          category: "Photos"
        },
        {
          label: "Photo 4",
          src: "media/link.com",
          thumbnail:
            "https://klike.net/uploads/posts/2019-01/medium/1547365387_4.jpg",
          category: "Photos"
        },
        {
          label: "Photo 5",
          src: "media/link.com",
          thumbnail:
            "https://klike.net/uploads/posts/2019-01/medium/1547365356_5.jpg",
          category: "Photos"
        },

        {
          label: "Video 1",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Vids"
        },
        {
          label: "Video 2",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Vids"
        },
        {
          label: "Video 3",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Vids"
        },

        {
          label: "Audio 1",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Audio"
        },
        {
          label: "Audio 2",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Audio"
        },
        {
          label: "Audio 3",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Audio"
        },

        {
          label: "Documents 1",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Documents"
        },
        {
          label: "Documents 2",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Documents"
        },
        {
          label: "Documents 3",
          src: "media/link.com",
          thumbnail: "thumbnail/link.com",
          category: "Documents"
        }
      ]
    }
  },
  methods: {
    add_click() {
      console.log('add_click')
    },
    grid_click(e) {
      console.log('grid_click', e)
    },
    show_popup_gallery() {
      this.$refs.ac_resource_gallery_popup.show_popup()
    },
    input_resource(val) {
       console.log('input_resource', val)
    },
    choose_resource(val) {
      console.log('choose_resource', val)
    }
  }
}
</script>

<style scoped>

</style>
