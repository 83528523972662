<template>
<ac-dynamic-kanban  :stage_url="stageUrl" :show_loadmore="showLoadmore" :limit="5" limit_key="limit" :data_cout_url="data_cout_url"
 offset_key="offset" :item_url="url" stage_append_key="id" item_append_key="id" :bulk_action="true">
    <template slot="header" scope="{ headItem }">
       <h6 class="card-title text-uppercase text-truncate">{{headItem.name}}</h6>
    </template>
    <template slot="body" scope="{ item }">
      <div class="card-body p-2">
            <div class="card-title">
               <img :src="item.owner_pic" class="rounded-circle float-right">
               <a href="" class="lead font-weight-light">{{item.subject}}</a>
            </div>
            <p>{{item.source}}</p>
            
      </div>
   </template>
   <template slot="empty_state">
      <p>This is empty state slot</p>
   </template>
</ac-dynamic-kanban>    
</template>
<script>
export default {
    data(){
       return {
          showLoadmore:true,
          stageUrl: "https://api.myjson.com/bins/1d0l3z",
          url:"https://api.myjson.com/bins/13hnfj?id=`${id}`",
          data_cout_url:"https://api.myjson.com/bins/13hnfj?fields=count(*)&id=`${id}`"
       }
    }
}
</script>


