<template>
  <div>
    <ac-swatches @input="showColor($event)" :colors="colors" v-model="color">
      <template v-slot:title>This is header slot</template>
    </ac-swatches>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: ["#F64272", "#F6648B", "#F493A7", "#F891A6", "#FFCCD5"],
      color: "#F6648B"
    };
  },
  methods: {
    showColor(e) {
      console.log(e);
    }
  }
};
</script>

<style>
</style>
