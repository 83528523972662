<template>
<div>
    <p>Use console for this</p>
     
     <ac-timeago
              ref="ac_timeago"
              date="10-09-2019"
              in_date_format="DD-MM-YYYY"
              out_date_format="MM-DD-YYYY"
            />
     <h5> 
       Converted_date: {{converted_date}}
     </h5>
     <h4>Elapsed Time: {{agoDate}} </h4>
     <div>fromDate: {{fromDate}}</div>
     <div>
       custom_date_fun: {{custom_date_fun}}
     </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      converted_date: "",
      agoDate: "",
      fromDate: "",
      custom_date_fun: ""
    };
  },
  mounted() {
    this.converted_date = this.$refs.ac_timeago.convert_date("10-09-2019", "YYYY-MM-DD");
    this.agoDate = this.$refs.ac_timeago.from_now();
    this.fromDate = this.$refs.ac_timeago.from();
    this.custom_date_fun = this.$refs.ac_timeago.custom_from_date("10-10-2019", "+7days, 1month");
  }
};
</script>

<style>
</style>
