<template>
  <ac-collection-card
    ref="ac_collection_card_example"
    :url="collection.url"
    :method="collection.method"
    :params="collection.params"
    :loading_image="collection.loading_image"
    :sort_key="collection.sort_key"
    :bulk="true"
    :dropdown_options="dropdown.options"
    :show_dropdown_icons="true"
    :card_options="{
                'text-variant': 'primary'
              }"
    :ac_cursor="collection.ac_cursor"
    :card_img_hover="hover_html"
    :search_key="search_key"
    :show_searchbar="show_searchbar"
    searchbar_placeholder="Search here"
    pagination_type="pagination"
    load_more_type="deselect"
    data_count_url="https://api.myjson.com/bins/mcwsk"
    count_key="count"
    :limit="limit"
    :offset_key="offset_key"
    :limit_key="limit_key"
    align="vertical"
    card_title="name"
    card_subtitle="id"
    card_body="body"
    card_header="postId"
    card_footer="name"
    card_img="image"
    card_body_html="p"
    loading_img="http://css.500apps.com/animated/trackly.png"
    :show_default_card="show_default_card"
  >
    <template #defaultCard>
      <b-card
        title="Card Title"
        img-src="https://picsum.photos/600/300/?image=25"
        img-alt="Image"
        img-top
        tag="article"
      >
        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>

        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </template>
    <template #load_more_button>tg676y7o lcvc</template>
    <template #emptyState>
      <p>
        <center>test emptyState slot</center>
      </p>
    </template>
  </ac-collection-card>
</template>

<script>
export default {
  data: function() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {},
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      hover_html:
        '<div class="bg-light text-success w-100 h-100 d-flex justify-content-center align-items-center">HOVER</div>',
      // card_links: [
      //   {
      //     label: "First",
      //     href: "/first",
      //     variant: "primary"
      //   },
      //   {
      //     label: "Second",
      //     href: "/second",
      //     variant: "success"
      //   }
      // ],
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt",
            handler: "hello"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt",
            handler: "hello"
          }
        ],
        button_options: {
          icon: "far fa-ellipsis-h",
          variant: "bg-transparent p-0",
          size: "sm",
          right: true
        }
      },
      show_default_card: false,
      search_key: "name",
      show_searchbar: true,
      limit: 5,
      offset_key: "_start",
      limit_key: "_limit"
    };
  },
  mounted() {
    this.$refs.ac_collection_card_example.set_ajax();
    console.log(this.$refs);
  }
};
</script>

<style>
</style>
