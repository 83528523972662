<template>
  <div>
    <!-- <button @click="update_json()">update</button> -->
    <ac-vertical-bar
      :vertical_menu_items="side_icons"
      :items="json"
      :categories="category_icon"
      @category_click="show_me_category($event)"
      @item_click="item_click($event)"
      item_active_class="bg-dark"
      item_mute_class="text-muted"
      :show_vertical_menu="true"
      ref="vn"
      vertical_bar_class="bg-danger"
    >
      <a slot="logo" class="nav-link p-2 text-nowrap btn btn-dark">
        <i class="fas fa-lock">SLOT</i>
      </a>

      <div slot="header">
        <a class="nav-link d-flex justify-content-between px-2 pb-0">
          <div>
            <b-dropdown
              variant="border-0 transparent p-0"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template slot="button-content">
                <div class="nav-link text-white mb-0">
                  <h5 class="mb-md-0">{{current_user.workspace}}</h5>
                  <template class="nav-link p-0 text-left">
                    <span class="small mr-2">
                      <i :class="current_user.status_icon+' small font-weight-bold'"></i>
                    </span>
                    <span class="small">{{current_user.name}}</span>
                  </template>
                </div>
              </template>
              <b-dropdown-item>
                <div class="media">
                  <img
                    :src="current_user.img"
                    width="35"
                    height="35"
                    alt="Generic placeholder image"
                    class="mr-3 rounded"
                  />
                  <div class="media-body">
                    <h5 class="mt-0 mb-md-0">{{current_user.first_name}} {{current_user.last_name}}</h5>
                    <span class="text-muted small">{{current_user.email}}</span>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(item,index) in current_user.user_profile"
                :key="index"
              >{{item.items}}</b-dropdown-item>
            </b-dropdown>
            <span class="text-white-50 p-0">
              <template
                class="navbar-brand rounded-circle d-flex align-items-center pt-0 font-weight-bold"
              >
                <img :src="current_user.logo" width="36" />
              </template>
            </span>
          </div>
          <div>
            <div>
              <b-dropdown
                variant="border-0 transparent p-0"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template slot="button-content">
                  <i :class="current_user.submenu_icon" class="fa-lg nav-link float-left"></i>
                </template>
                <b-dropdown-item
                  v-for="(notification_item,notification_index) in current_user.submenu"
                  :key="notification_index"
                >{{notification_item.items}}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </a>
      </div>

      <template v-slot:status="{item}">
        <template v-if="item.status">
          <i class="fas fa-circle fa-sm text-success small font-weight-bold slot"></i>
        </template>
        <template v-if="item.private">
          <i class="fas fa-lock fa-sm small font-weight-bold"></i>
        </template>
        <template v-if="item.private == false">
          <i class="fal fa-hashtag fa-sm small"></i>
        </template>
      </template>

      <template v-slot:favorite="{item}">
        <i class="nav-link pr-0 fas fa-star" @click="un_fav_item(item)"></i>
      </template>

      <template v-slot:non_favorite="{item}">
        <i class="nav-link pr-0 far fa-star" @click="fav_item(item)"></i>
      </template>

      <template v-slot:unread="{count}">
        <div class="text-center">
          <b-badge pill variant="warning">{{count}}</b-badge>
        </div>
      </template>

      <div slot="search" class="pt-3 mb-3 px-3">
        <div class="input-group mb-0">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-search"></i>
            </span>
          </div>
          <input type="text" class="form-control pl-2" placeholder="Search..." />
        </div>
      </div>

      <!-- <div slot="footer">
        <b-badge variant="success">I am Footer</b-badge>
      </div>-->
    </ac-vertical-bar>
    <!-- <button @click="update()">Update JSON</button> -->
  </div>
</template>

<script>
export default {
  methods: {
    update_json() {
      //this.ajson[0]["category"] = "Favorites"
      this.json.push({
        name: "XUTE",
        status: true,
        category: "Favorites",
        mute: true
      });
    },
    update() {
      //this.$refs.vn.applyActiveClassById(2);
      this.$refs.vn.refresh_content();
    },
    show_me_category() {
      // Will get category as c
    },
    item_click(item) {
      // Get the item which was clicked
      // Apply active class by using refs
      // this.$refs.vn.apply_active_class_by_id(item.id)
    },
    fav_item() {
      // Get the item which was favorited
    },
    un_fav_item() {
      // Get the item which was un-favorited
    }
  },
  data() {
    return {
      header2: true,
      side_icons: [
        { icon: "far fa-infinity", active: true, href: "/home" },
        { icon: "fal fa-acorn", active: false, href: "/settings" },
        { icon: "fab fa-android", active: false, href: "/profile" }
      ],
      category_icon: [
        {
          icon: "fal fa-plus-circle",
          name: "Favorites"
        },
        {
          icon: "fal fa-plus-circle",
          name: "Channels"
        },
        {
          icon: "fab fa-android",
          name: "Direct Messages"
        }
      ],
      json: [
        {
          id: "1",
          name: "Sahitya",
          private: true,
          category: "Channels",
          mute: true
        },
        {
          id: "2",
          name: "12",
          private: true,
          category: "Channels",
          mute: false
        },
        {
          id: "3",
          name: "123",
          private: true,
          category: "Channels",
          mute: true
        },
        {
          id: "4",
          name: "Sai",
          private: false,
          category: "Channels",
          unread: 3,
          mute: false
        },
        {
          id: "5",
          name: "Suren",
          status: true,
          category: "Favorites",
          mute: false
        },
        {
          id: "6",
          name: "mute",
          status: true,
          category: "Favorites",
          mute: true
        },
        {
          id: "7",
          name: "mute-active",
          status: true,
          category: "Favorites",
          active: true,
          mute: true
        },
        {
          id: "8",
          name: "T",
          status: true,
          category: "Direct Messages",
          active: false,
          mute: true
        },
        {
          id: "9",
          name: "Tabrez",
          status: true,
          category: "Direct Messages",
          unread: 1,
          active: true,
          mute: false
        }
      ],
      current_user: {
        workspace: "Appup",
        name: "Suma",
        status_icon: "fas fa-circle fa-sm text-success",
        img:
          "https://latestmodapks.com/wp-content/uploads/2017/04/image_20170412_112801_50-264x300.jpg",
        first_name: "sumalatha",
        last_name: "burla",
        email: "sumalatha.burla@yopmail.com",
        submenu_icon: "fal fa-bell",
        submenu: [
          { items: "Set a Status" },
          { items: "Clear Status" },
          { items: "Team" },
          { items: "User profile" }
        ],
        user_profile: [
          { items: "Set a Status" },
          { items: "Clear Status" },
          { items: "Team" }
        ]
      },
      current_user_2: {
        logo: "https://www.agilecrm.com/test/trackly-white-logo.svg",
        submenu_icon: "fal fa-bell",
        submenu: [
          { items: "Set a Status" },
          { items: "Clear Status" },
          { items: "Team" },
          { items: "User profile" }
        ]
      }
    };
  }
};
</script>