<template>
  <div>
    <ac-collection-grouped-card
      ref="ac_collection_grouped_card"
      :url="collection.url"
      :method="collection.method"
      :params="collection.params"
      :loading_image="collection.loading_image"
      :sort_key="collection.sort_key"
      :bulk="true"
      :card_links="card_links"
      :card_options="{
                'is_delete': true
              }"
      :tabs_attrs="{ 'card': true, pills: true }"
      :ac_cursor="collection.ac_cursor"
      :card_img_hover="hover_html"
      :dropdown_button_options="dropdown_button_options"
      :dropdown_options="dropdown_options"
      :show_dropdown_icons="true"
      :show_header="true"
      :show_footer="true"
      ac_type="simple-label"
      card_header="id"
      align="horizontal"
      card_title="name"
      card_subtitle="email"
      card_body="body"
      card_footer="name"
      card_img="image"
      card_body_html="p"
      group_key="postId"
      :enable_drag="true"
      @on-drag="onDragCheck"
    >
      <template v-slot:header>Main Header</template>

      <template v-slot:group_header="{ groupItem }">Group {{ groupItem.postId }}</template>

      <template v-slot:group_footer>group Footer</template>
      <template #empty_state>No Data Exists</template>
      <template v-slot:footer>Main footer</template>
    </ac-collection-grouped-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      hover_html:
        '<div class="bg-light text-success w-100 h-100 d-flex justify-content-center align-items-center">HOVER</div>',
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          _limit: 10
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      card_links: [
        {
          label: "First",
          href: "/first",
          variant: "primary"
        },
        {
          label: "Second",
          href: "/second",
          variant: "success"
        }
      ],
      dropdown_options: [
        {
          label: "Edit",
          type: "edit",
          icon: "fal fa-pencil-alt",
          handler_params: "{}"
        },
        {
          label: "Delete",
          type: "delete",
          icon: "fal fa-trash-alt",
          handler_params: "{}"
        }
      ],
      dropdown_button_options: {
        icon: "fal fa-ellipsis-h",
        variant: "bg-transparent p-0",
        size: "lg",
        right: true
      }
    };
  },
  mounted() {
    this.$refs.ac_collection_grouped_card.set_ajax();
  },
  methods: {
    onDragCheck(testd, dragged_ids) {
      console.log("testd..", testd, dragged_ids);
    }
  }
};
</script>

<style>
</style>
