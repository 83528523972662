<template>
  <div>
    <ac-grouped-dropdown
      :data="a"
      variant="link"
      key_label="name"
      @dd-item-clicked="emited"
      :url="Url"
    >
      <template #drop_down_item="{item}">
        <div>
          {{item.name}}
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </div>
      </template>
    </ac-grouped-dropdown>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      Url: "https://api.myjson.com/bins/19gmx7",
      a: [
        {
          name: "support",
          id: 1,
          items: [
            {
              name: "sub1",
              id: 11
            },
            {
              name: "sub2",
              id: 12
            },
            {
              name: "sub3",
              id: 13
            }
          ]
        },
        {
          name: "newww",
          id: "2",
          items: [
            {
              name: "new1",
              id: 21
            },
            {
              name: "new2",
              id: 22
            },
            {
              name: "new3",
              id: 23
            }
          ]
    }]
  }
  },
  methods: {
    emited(data) {
      console.log("selected data is ", data);
    }
  }
};
</script>
