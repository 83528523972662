<template>
    <div>
        <ac-my-simple-card :datacard="datacard" :isrow="isrow" :colclass="colclass" :rowclass="rowclass" />
    </div>
</template>

<script>
export default {
data(){
    return{
        colclass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 pr-md-3 pr-0 pl-0 pb-3",
        rowclass:"row",
        isrow:true,
        datacard: [
            {
                name:"Hours Worked",
                icon:"far fa-info-circle",
                time:"Jul 07, 2019 - Jul 13, 2019",
                time1:"00:15 Hrs",
                pec:"100.00%",
                time2:"00:00 Hrs",
                color:"bg-primary",
                width:"98.84%"
            },
               {
                name:"Earnings",
                icon:"far fa-info-circle",
                time:"Jul 07, 2019 - Jul 13, 2019",
                time1:"$27.5",
                pec:"100.00%",
                time2:"$0:00",
                color:"bg-success",
                width:"98.84%"
            },
             {
                name:"Activity",
                icon:"far fa-info-circle",
                time:"Jul 07, 2019 - Jul 13, 2019",
                time1:"00:00 Hrs",
                pec:"31.92",
                time2:"$0:00",
                color:"bg-danger",
                width:"50%"
            }

        ]
    }
}
}
</script>

<style>

</style>
