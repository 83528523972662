<template>
  <div>
    <ac-codemirror v-model="value" :options="cmOptions"></ac-codemirror>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "this is testing",
      cmOptions: {
        tabSize: 4,
        mode: "text/javascript",
        theme: "base16-light",
        lineNumbers: true,
        line: true
      }
    };
  }
};
</script>