<template>
    <div>
        <ac-sort
              :options="sort_options"
              :collection_ref="ac_sort_collection"
              class="mb-2"
            />

            <ac-collection
              ref="ac_sort_collection"
              :url="collection.url"
              :method="collection.method"
              :ac_cursor="collection.ac_cursor"
              :params="collection.params"
              :loading_image="collection.loading_image"
              :sort_key="collection.sort_key"
            >
              <template #header>
                <header class="border p-2 mb-2">
                  Ac collection header
                </header>
              </template>

              <template #body="{ data }">
                <b-row>
                  <b-col
                    v-for="item in data"
                    :key="item.id"
                    class="ac__item"
                    :lg="6"
                  >
                     <b-card
                      :title="item.name"
                      class="mb-2"
                    >
                      <b-card-text> {{ item.body }}</b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>

              <template #footer>
                <footer class="border p-2 mt-2">
                  ac collection footer
                </footer>
              </template>
            </ac-collection>
    </div>
</template>

<script>
import SortOptions from '../src/assets/sort.json'
export default {
  data: function () {
      return {
          sort_options: SortOptions,
          ac_sort_collection: {},
          collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      }
      }
  },
  mounted(){
      this.ac_sort_collection = this.$refs.ac_sort_collection
      this.$refs.ac_sort_collection.set_ajax();
  }
}
</script>

<style>

</style>
