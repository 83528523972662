<template>
  <div>
    <b-button @click="$refs.ac_upload.show()">Upload file</b-button>
    <ac-image-upload
      ref="ac_upload"
      title="Files"
      type="img/doc"
      extensions=".jpg, .png, .svg"
      max_file_size="10000"
      :max_img_height="1000"
      :max_img_width="1000"
      upload_url="https://jsonplaceholder.typicode.com/posts"
      gallery_url="https://jsonplaceholder.typicode.com/photos?albumId=1"
      @select="event_test"
    />
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
