<template>
  <ac-move-up-down
    ref="ac_collection"
    :url="collection.url"
    :method="collection.method"
    :ac_cursor="{}"
    :params="collection.params"
    :loading_image="collection.loading_image"
    :sort_key="collection.sort_key"
    @item-moved="dragged"
  >
    <template #header>
      <header class="border p-2 mb-2">header</header>
    </template>

     <template #item={item}>
         <b-card>{{item.name}}</b-card>
     </template>
     
    <template #emptyState>   
       <p>From emptyState Slot</p>
    </template>

    <template #footer>
      <footer class="border p-2 mt-2">footer</footer>
    </template>
  </ac-move-up-down>
</template>

<script>
export default {
  data() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments?postId=1",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "email"
      }
    };
  },
  methods:{
      dragged(event){
          console.log('item moved', event)

      }
  }
};
</script>

<style scoped>
.card-head-bg .card-title .ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
