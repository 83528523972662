<template>
  <div>
    <ac-calendar :events="events" @day_click="check($event)" @event_selected="check($event)" />
  </div>
</template>
<script>
export default {
  name: "calendar",
  methods: {
    check: function(event, a) {
      console.log(a);
      console.log(event);
    }
  },
  data() {
    return {
      events: [
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event1",
          start: "2019-09-01"
        },
        {
          title: "event2",
          start: "2018-10-05",
          end: "2018-10-07"
        },
        {
          title: "event3",
          start: "2018-10-09T12:30:00",
          allDay: false
        }
      ],
      config: {
        defaultView: "month",
        eventRender: function(event, element) {
          console.log(event);
        }
      }
    };
  }
};
</script>