<template>
  <ac-multi-search
    :loading_image="collection.loading_image"
    :types="[
                { label: 'All', value: 'all', checked: true },
                { label: 'Contacts', value: 'contacts', checeked: false },
              ]"
    url="https://jsonplaceholder.typicode.com/comments"
    search_param="postId"
    group_key="postId"
    :search_symbol="search_symbol"
    :placeholder="placeholder"
    @keyup="event_test"
  >
    <template #result_title="{ title }">
      <h5 class="mb-2 mt-3">{{ title }}</h5>
    </template>

    <template #result_body="{ item }">
      <p>{{item.email}}</p> 
    </template>

    <template #more_btn>
      <b-button variant="outline-primary">Button</b-button>
    </template>
  </ac-multi-search>
</template>

<script>
export default {
  data: function() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      search_symbol: true,
      placeholder: "Enter the value"
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
