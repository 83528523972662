<template>
  <div>
    <ac-inline-form-edit :form_json="formData" @updated_value="updatedValue"></ac-inline-form-edit>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: [
        {
          type: "text",
          value: "sj",
          placeholder: "Enter",
          label: "Name",
          name: "name",
          validateObj: { required: true, length: 5 },
          display: true,
          isInlineEnable: true
        },
        {
          type: "text",
          value: "jhsdfjhsjdfk@gmail.com",
          placeholder: "Enter",
          label: "Email",
          name: "email",
          validateObj: { required: true, email: true },
          display: true,
          isInlineEnable: true
        },
        {
          type: "text",
          value: "hafjhsjk",
          placeholder: "Enter",
          label: "Last name",
          name: "last_name",
          validateObj: { required: true },
          display: true,
          isInlineEnable: true
        },
        {
          type: "select",
          value: "",
          label: "Select",
          name: "select",
          url: "https://api.myjson.com/bins/pc45h",
          textDisplay: "Select",
          display: true,
          isInlineEnable: true,
          keylabel: "text",
          keyvalue: "value"
        },
        {
          type: "select",
          value: "a",
          textDisplay: "Select",
          label: "Name",
          name: "name2",
          options: [
            { value: "", text: "Please select an option" },
            { value: "a", text: "This is First option" },
            { value: "b", text: "Selected Option" },
            { value: "c", text: "This is an option with object value" }
          ],
          display: true,
          isInlineEnable: true,
          keylabel: "text",
          keyvalue: "value"
        },
        {
          type: "date",
          value: "22/11/2019",
          label: "Date",
          name: "date1",
          display: true,
          isInlineEnable: true
        },
        {
          type: "gravatar",
          label: "Image",
          name: "image",
          config: { size: 30, rounded: false },
          value: "gshgshf",
          email: ""
        },
        {
          type: "upload",
          label: "Upload Image",
          name: "file_name",
          upload_type: "text",
          uploadConfig: {
            uploadText: "upload       ",
            url: "https://cmsapps.500apps.com/websitecms/presign?file_name=",
            id: "file_id",
            name: "file_name",
            imagePreviewConfig: { height: "30px", width: "30px" },
            size: 1
          }
        }
      ]
    };
  },
  methods: {
    updatedValue: function(data) {
      console.log("data is" + data);
    }
  }
};
</script>
<style scoped>
:focus,
focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>