<template>
  <div id="app">
    <h4> Single value edit </h4>
    <template>
      <ac-inline-edit v-model="field_name"></ac-inline-edit>
      {{field_name}}
    </template>
    <h4> Inline edit for JSON </h4>
    <template v-for="item in formData">
      <ac-inline-edit v-model="item.value" :type="item.type"></ac-inline-edit>
    </template>
    <pre>{{formData}}</pre>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      field_name: "Field name",
      formData: [
        {
          type: "email",
          value: 'demo@demo.com',
          label: "Email"
        },
        {
          type: "text",
          value: 'name',
          label: "First name"
        },
        {
          type: "password",
          value: "123",
          label: "Password"
        },
        {
          type: "number",
          value: "12",
          label: "Phone number"
        }
      ]
    };
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
