<template>
        <ac-wizard-builder :rule_JSON="ruleJson" :prefill_data="prefillData" :workflow_JSON="workflowJson" :avail_tabs="availObj"
            :trigger_JSON="triggerJson" @outputJson="getOutput"></ac-wizard-builder>
    </template>
    <script>
        export default {
            data() {
                return {
                    availObj:{
                        trigger:true,
                        filter:true,
                        action:true,
                    },
                    // ruleJson: {
                    //     "condition": "Or",
                    //     "selectedKey": "",
                    //     "selectedOperator": "",
                    //     "selectedVal": "",
                    //     "keys": {
                    //         "url": "https://restcountries.eu/rest/v2/all",
                    //         "keyvalue": "alpha2Code",
                    //         "keytext": "name",
                    //         "keytype": "select",
                    //         "options": []
                    //     },
                    //     "operators": {
                    //         "text": [{ label: "Equals", value: "EQ" }, { label: "Not Equals", value: "NE" }],
                    //         "date": [{ label: "Equal", value: "EQ" }, { label: "Not Equals", value: "NE" }],
                    //         "number": [{ label: "Equals", value: "EQ" }, { label: "Not Equals", value: "NE" }],
                    //         "select": [{ label: "Equals", value: "EQ" }, { label: "Not Equals", value: "NE" }]
                    //     },
                    //     "controls": {
                    //         "text": { type: "text" },
                    //         "date": {
                    //             "type": "appup-datetime",
                    //             "name": "date",
                    //             "input": "date",
                    //             "label": "Date Picker",
                    //             "placeholder": "Date here",
                    //             "custom": {
                    //                 "dateFormat": "m-Y-d"
                    //             }
                    //         },
                    //         "number": { type: "number" },
                    //         "select": { type: "select", url: "https://restcountries.eu/rest/v2/all", keyvalue: "alpha2Code", keytext: "name", options: [] }
                    //     }
                    // },
                    ruleJson:{
    "condition": "Or",
    "selectedKey": "",
    "selectedOperator": "",
    "selectedVal": "",
    "keys": {
    "url": "https://support.appup.cloud/support/landing/get/automation_metadata?fields=id,name,conditions,options,retrieve%20as%20url,format%20as%20type&where=type=%27condition%27%20and%20name%20in%20(%27Assigned%20to%27,%27Ticket%20tags%27,%27Priority%27,%27Status%27,%27Type%27,%27Group%20name%27,%27Requester%20email%27,%27Ticket%20participants%27,%27Subject%27,%27Source%27)",
    "keyvalue": "id",
    "keytext": "name",
    "options": []
    },
    "operators": {
    "text": [{
    "text": "Equals",
    "value": "EQ"
    }, {
    "text": "Not Equals",
    "value": "NE"
    },{
    "text": "INBETWEEN",
    "value": "INBTW"
    }],
    "date": [{
    "text": "Equal",
    "value": "EQ"
    }, {
    "text": "Not Equals",
    "value": "NE"
    }],
    "number": [{
    "text": "Equals",
    "value": "EQ"
    }, {
    "text": "Not Equals",
    "value": "NE"
    }],
    "select": [{
    "text": "Equals",
    "value": "EQ"
    }, {
    "text": "Not Equals",
    "value": "NE"
    }]
    },
    "controls": {
    "text": {
    "type": "text"
    },
    "date": {
    "type": "appup-datetime",
    "name": "date",
    "input": "date",
    "label": "Date Picker",
    "placeholder": "Date here",
    "custom": {
    "dateFormat": "m-Y-d"
    }
    },
    "number": {
    "type": "number"
    },
    "select": {
    "type": "select",
    "url": "",
    "keyvalue": "id",
    "keytext": "name",
    "options": []
    }
    }
    },
                    prefillData: [
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "Or",
                        //             "selectedKey": "AX",
                        //             "selectedOperator": "EQ",
                        //             "selectedVal": "EA",
                        //         }
                        //     ],
                        //     "condition": "And"
                        // },
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "And",
                        //             "selectedKey": "",
                        //             "selectedOperator": "",
                        //             "selectedVal": "",
                        //         }
                        //     ],
                        //     "condition": "Or"
                        // },
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "Or",
                        //             "selectedKey": "",
                        //             "selectedOperator": "",
                        //             "selectedVal": ""
                        //         }
                        //     ],
                        //     "condition": "Or"
                        // },
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "Or",
                        //             "selectedKey": "",
                        //             "selectedOperator": "",
                        //             "selectedVal": ""
                        //         }
                        //     ],
                        //     "condition": "Or"
                        // },
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "Or",
                        //             "selectedKey": "",
                        //             "selectedOperator": "",
                        //             "selectedVal": ""
                        //         }
                        //     ],
                        //     "condition": "Or"
                        // },
                        // {
                        //     "rules": [
                        //         {
                        //             "condition": "Or",
                        //             "selectedKey": "",
                        //             "selectedOperator": "",
                        //             "selectedVal": ""
                        //         }
                        //     ],
                        //     "condition": "Or"
                        // }
                    ],
                    triggerJson: {
                        "url": "",
                        "keyvalue": "id",
                        "keytext": "name",
                        "keytype": "select",
                        "options": [{text:"fsdfsf",value:"sdfsfsdfsdfs"}]
                    },
                    workflowJson:{
                                "id": 1010,
                                "type": "workflow",
                                "name": "JSON_CODE",
                                "label": "Workflow",
                                "src": " https://our.appup.com/app/designer/designer.html",
                                "description": "We will load designer here",
                                "width": "90%",
                                "height": "900px",
                                "placeholder": "Enter Email",
                                "required": true,
                                "class": "col-sm-12",
                                "toolbar1": [
                                    {
                                        "name": "JS code",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Enter js code to execute.",
                                        "help": "Enter js code to execute.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "js"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Code",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "code",
                                                "id": "code",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter js code to execute.",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Enter js code to execute.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Noty Permission",
                                        "thumbnail": "json/nodes/images/common/url.png",
                                        "icon": "json/nodes/icons/common/transfer.png",
                                        "info": "To ask permission to send notifications",
                                        "help": "To ask permission to send notifications",
                                        "author": "Sandeep",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success,failure",
                                        "type": {
                                            "name": "notiRequestAccess"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers"
                                    },
                                    {
                                        "name": "CurrentPosition",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Get the current position by using this node",
                                        "help": "Get the current position by using this node",
                                        "author": "Sandeep",
                                        "company": "500 Apps",
                                        "language": "en",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "type": {
                                            "name": "get_current_position"
                                        },
                                        "branches": "failure,success",
                                        "category": "Mobile",
                                        "ui": [
                                            {
                                                "label": "Latiude Variable Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "latitude",
                                                "id": "Latitude",
                                                "title": "Enter Latitude Variable Name",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Longitude Variable Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "longitude",
                                                "title": "Enter Longitude Variable Name",
                                                "id": "Longitude",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Get current position (lat and long) by using this node",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Notification",
                                        "thumbnail": "json/nodes/images/common/url.png",
                                        "icon": "json/nodes/icons/common/transfer.png",
                                        "info": "create notification with title,body, Image and badge",
                                        "help": "create notification with title,body, Image and badge",
                                        "author": "Sandeep",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "createNotification"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Title",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "title",
                                                "id": "noti_title",
                                                "title": "Enter Notification Title",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Body",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "body",
                                                "id": "noti_body",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter Notification Message",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Timeout",
                                                "category": "Info",
                                                "name": "timeout",
                                                "id": "noti_timeout",
                                                "title": "Enter Notification Timeout",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "create Notification.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Navigate",
                                        "thumbnail": "json/nodes/images/common/url.png",
                                        "icon": "json/nodes/icons/common/transfer.png",
                                        "info": "Enter path to navigate.",
                                        "help": "Enter path to navigate.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "navigate"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Path",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "url",
                                                "id": "url",
                                                "title": "Enter path to navigate.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Enter path to navigate.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Validate",
                                        "thumbnail": "json/nodes/images/Condition.png",
                                        "icon": "json/nodes/icons/common/SetProperty.png",
                                        "info": "Validates form fields.",
                                        "help": "Validates form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success,failure",
                                        "type": {
                                            "name": "validate"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Select form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Select form to validate.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Validates form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Alert",
                                        "thumbnail": "json/nodes/images/Announcement.png",
                                        "icon": "json/nodes/icons/common/push.png",
                                        "info": "Enter title and message in the alert.",
                                        "help": "Enter title and message in the alert.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "alert"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Title",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "title",
                                                "id": "title",
                                                "title": "Enter title to alert.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Message",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "message",
                                                "id": "message",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter message to alert.",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Enter title and message in the alert.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Serialize",
                                        "thumbnail": "json/nodes/images/crm/status.png",
                                        "icon": "json/nodes/icons/new/sql-update.png",
                                        "info": "Serializes form fields.",
                                        "help": "Serializes form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "serialize"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Output",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "output",
                                                "id": "output",
                                                "title": "Enter output variable to hold serialized data",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Enter form id.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Serializes form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "GetCurrentPosition",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Get the current position by using this node",
                                        "help": "Get the current position by using this node",
                                        "author": "Sandeep",
                                        "company": "500 Apps",
                                        "language": "en",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "type": {
                                            "name": "get_current_position"
                                        },
                                        "branches": "failure,success",
                                        "category": "Mobile",
                                        "ui": [
                                            {
                                                "label": "Latiude Variable Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "latitude",
                                                "id": "Latitude",
                                                "title": "Enter Latitude Variable Name",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Longitude Variable Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "longitude",
                                                "title": "Enter Longitude Variable Name",
                                                "id": "Longitude",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Get current position (lat and long) by using this node",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "WatchPosition",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Watch the current position by using this node",
                                        "help": "Watch the current position by using this node",
                                        "author": "Tabrez",
                                        "company": "500 Apps",
                                        "language": "en",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "type": {
                                            "name": "watch_position"
                                        },
                                        "branches": "failure,success",
                                        "category": "Mobile",
                                        "ui": [
                                            {
                                                "label": "Hanlder Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "handler",
                                                "id": "Handler",
                                                "title": "Enter Hanlder Name",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Enable High Accuracy?",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "enableHighAccuracy",
                                                "title": "Want to enable High Accuracy?",
                                                "id": "EnableHighAccuracy",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Watch current position by using this node",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Clear Watch",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Stop watching for changes to device location",
                                        "help": "Stop watching for changes to device location",
                                        "author": "Sandeep",
                                        "company": "500 Apps",
                                        "language": "en",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Mobile",
                                        "ui": [
                                            {
                                                "label": "Code",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "clear_watch_code",
                                                "id": "clear_watch_code",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter or modify clear watch code",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Stop watching for changes to device location",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "TwilioIncomingCall",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Fires when call is incoming",
                                        "help": "Incoming call node",
                                        "author": "Tabrez",
                                        "company": "500 Apps",
                                        "language": "en",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "type": {
                                            "name": "twilio_incoming_call"
                                        },
                                        "branches": "failure,success",
                                        "category": "Mobile",
                                        "ui": [
                                            {
                                                "label": "Client Number",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "clientNumber",
                                                "title": "Enter Client Number",
                                                "id": "ClientNumber",
                                                "fieldType": "input",
                                                "type": "text"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Deserialize",
                                        "thumbnail": "json/nodes/images/Variables.png",
                                        "icon": "json/nodes/icons/common/addtask.png",
                                        "info": "Deserializes form fields.",
                                        "help": "Deserializes form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "deserialize_form"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Data",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "data",
                                                "id": "data",
                                                "title": "Enter data to deserialize",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Enter form id.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deserializes form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Ajax",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/common/jsonio.png",
                                        "info": "Perform an asynchronous HTTP request within workflow.",
                                        "help": "Perform an asynchronous HTTP request within workflow.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "type": {
                                            "name": "ajax"
                                        },
                                        "branches": "failure,success",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.JSONNode",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "URL:",
                                                "category": "Settings",
                                                "required": "required",
                                                "name": "url",
                                                "title": "Please enter a valid HTTP URL",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Output Variable",
                                                "category": "More Settings",
                                                "required": "required",
                                                "name": "var",
                                                "title": "Please enter a variable to store output",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Method type:",
                                                "required": "No",
                                                "category": "Settings",
                                                "name": "method",
                                                "title": "Select the appropriate type for accessing the URL.",
                                                "options": {
                                                    "*GET": "get",
                                                    "POST": "post",
                                                    "PUT": "put",
                                                    "DELETE": "delete"
                                                },
                                                "fieldType": "select",
                                                "type": "select"
                                            },
                                            {
                                                "label": "JSONP:",
                                                "required": "No",
                                                "category": "Settings",
                                                "name": "jsonp",
                                                "title": "Select the appropriate accessing the URL.",
                                                "options": {
                                                    "*NO": false,
                                                    "YES": true
                                                },
                                                "fieldType": "select",
                                                "type": "select"
                                            },
                                            {
                                                "label": "Post Data",
                                                "category": "Settings",
                                                "required": "required",
                                                "name": "data",
                                                "title": "Please enter the data variable you want to send",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Params",
                                                "category": "Settings",
                                                "name": "data_params",
                                                "type": "grid",
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "name": "name",
                                                        "required": "required",
                                                        "title": "Please enter keyname",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "name": "value",
                                                        "required": "required",
                                                        "title": "Please enter value",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ],
                                                "defaultvalues": [
                                                    {
                                                        "keyName": "name",
                                                        "valueName": "value"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Headers",
                                                "category": "Headers",
                                                "name": "headers",
                                                "type": "grid",
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "name": "name",
                                                        "required": "required",
                                                        "title": "Please enter keyname",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "name": "value",
                                                        "required": "required",
                                                        "title": "Please enter value",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ],
                                                "defaultvalues": [
                                                    {
                                                        "keyName": "Accept",
                                                        "valueName": "text/plain"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Perform an asynchronous HTTP request within workflow.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    }
                                ],
                                "toolbar2": [
                                    {
                                        "name": "Set Cookie",
                                        "thumbnail": "json/nodes/images/crm/status.png",
                                        "icon": "json/nodes/icons/new/sql-insert.png",
                                        "info": "Saves given key-value pairs in browser cookies.",
                                        "help": "Saves given key-value pairs in browser cookies.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "set_cookie"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.NewCondition",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Cookie",
                                                "category": "Settings",
                                                "name": "cookie_key_grid",
                                                "type": "grid",
                                                "style": {
                                                    "overflow-y": "scroll"
                                                },
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "id": "cookie_key",
                                                        "class": "cookie_key",
                                                        "name": "cookie_key",
                                                        "title": "Enter cookie key name.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "name": "cookie_value",
                                                        "title": "Enter cookie value.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Saves given key-value pairs in browser cookies.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Delete Cookie",
                                        "thumbnail": "json/nodes/images/new/sql-delete-node.png",
                                        "icon": "json/nodes/icons/new/sql-delete.png",
                                        "info": "Deletes cookie value of given key",
                                        "help": "Deletes cookie value of given key.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "delete_cookie"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Cookie Key",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "cookie_key",
                                                "id": "cookie_key",
                                                "title": "Enter cookie key to delete.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deletes cookie value of given key.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Set Local Storage",
                                        "thumbnail": "json/nodes/images/new/sql-insert-node.png",
                                        "icon": "json/nodes/icons/new/sql-insert.png",
                                        "info": "Saves given key-value pairs in browser local storage.",
                                        "help": "Saves given key-value pairs in browser local storage.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "set_local_storage"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.NewCondition",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Local Storage",
                                                "category": "Settings",
                                                "name": "ls_key_grid",
                                                "type": "grid",
                                                "style": {
                                                    "overflow-y": "scroll"
                                                },
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "id": "ls_key",
                                                        "class": "ls_key",
                                                        "name": "ls_key",
                                                        "title": "Enter local storage key name.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "name": "ls_value",
                                                        "title": "Enter local storage value.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Saves given key-value pairs in browser local storage.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Delete Local Storage",
                                        "thumbnail": "json/nodes/images/new/sql-delete-node.png",
                                        "icon": "json/nodes/icons/new/sql-delete.png",
                                        "info": "Deletes local storage value of given key",
                                        "help": "Deletes local storage value of given key.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "delete_local_storage"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Local Storage Key",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "ls_key",
                                                "id": "ls_key",
                                                "title": "Enter local storage key to delete.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deletes local storage value of given key.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    }
                                ],
                                "marketplace": [
                                    {
                                        "name": "Modal Helper",
                                        "thumbnail": "json/nodes/images/Variables.png",
                                        "icon": "json/nodes/icons/crm/notify.png",
                                        "info": "Enter helper name in the modal.",
                                        "help": "Enter title and message in the modal.",
                                        "author": "Appup Developer",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "modal_helper"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "name": "helper",
                                                "id": "helper",
                                                "label": "Helper name",
                                                "required": "required",
                                                "category": "Info",
                                                "title": "Enter name of helper to modal.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Title",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "modal-title",
                                                "id": "modal-title",
                                                "title": "Enter title to modal.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "OK button title",
                                                "category": "Info",
                                                "name": "modal-ok-title",
                                                "id": "modal-ok-title",
                                                "title": "Enter title to ok button.",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "OK"
                                            },
                                            {
                                                "label": "Cancel button title",
                                                "category": "Info",
                                                "name": "modal-cancel-title",
                                                "id": "modal-cancel-title",
                                                "title": "Enter title to cancel button.",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "Cancel2"
                                            },
                                            {
                                                "options": {
                                                    "False": "false",
                                                    "True": "true"
                                                },
                                                "name": "modal-no-close-on-esc",
                                                "label": "Close on ESC?",
                                                "id": "modal-no-close-on-esc",
                                                "title": "Select option on esc",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select"
                                            },
                                            {
                                                "options": {
                                                    "False": "false",
                                                    "True": "true"
                                                },
                                                "name": "modal-no-close-on-backdrop",
                                                "label": "Close on backdrop?",
                                                "id": "modal-no-close-on-backdrop",
                                                "title": "Select option on backdrop",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select"
                                            },
                                            {
                                                "label": "Modal Config",
                                                "category": "Settings",
                                                "name": "config",
                                                "id": "modal-config",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter modal config",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "OK variant",
                                                "category": "Info",
                                                "name": "modal-ok-variant",
                                                "id": "modal-ok-variant",
                                                "title": "Enter variant for OK button",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "danger"
                                            },
                                            {
                                                "label": "Enter title and message in the modal.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "thumbnail": "json/nodes/images/check_campaign.png",
                                        "unique_branches": "yes",
                                        "dynamicports": "yes",
                                        "author": "Appup Developer",
                                        "dynamicbranch": "key",
                                        "dynamicportkey": "cases",
                                        "icon": "json/nodes/icons/common/switch.png",
                                        "language": "en",
                                        "branches": "",
                                        "type": {
                                            "name": "switch_case"
                                        },
                                        "material_icon": "format_list_bulleted",
                                        "workflow_tasklet_class_name": "com.appup.workflows.core.steps.SwitchCaseStepExecutor",
                                        "help": "Switch case Step",
                                        "ui": [
                                            {
                                                "name": "variable",
                                                "label": "Switch Case Input Variable",
                                                "id": "variable",
                                                "category": "Settings",
                                                "title": "Enter Switch Case Input Variable",
                                                "type": "text",
                                                "fieldType": "input",
                                                "required": true
                                            },
                                            {
                                                "ui": [
                                                    {
                                                        "name": "key",
                                                        "label": "Case",
                                                        "id": "key",
                                                        "title": "Enter value",
                                                        "type": "text",
                                                        "fieldType": "input",
                                                        "required": true
                                                    }
                                                ],
                                                "name": "cases",
                                                "label": "Cases",
                                                "id": "cases",
                                                "category": "Settings",
                                                "title": "",
                                                "type": "grid",
                                                "fieldType": "grid",
                                                "required": false
                                            }
                                        ],
                                        "material_icon_color": "blue",
                                        "displayname": "Switch case",
                                        "material_icon_bg_color": "bg_blue",
                                        "name": "Switch Case",
                                        "material_icon_img": "storage",
                                        "company": "Appup.com",
                                        "id": "Switch_Case_Step",
                                        "category": "Utilities",
                                        "info": "Evaluate a series of conditions and run the code corresponding to the true ones "
                                    },
                                    {
                                        "thumbnail": "json/nodes/images/sms/Condition.png",
                                        "author": "Appup Developer",
                                        "icon": "json/nodes/icons/sms/Condition.png",
                                        "language": "en",
                                        "branches": "success,failure",
                                        "type": {
                                            "name": "if_condition"
                                        },
                                        "material_icon": "compare_arrows",
                                        "workflow_tasklet_class_name": "com.appup.workflows.core.steps.IfConditionStepExecutor",
                                        "help": "If Condition Step",
                                        "ui": [
                                            {
                                                "options": {
                                                    "value": "value",
                                                    "strlen": "strlen"
                                                },
                                                "name": "if_type",
                                                "label": "If",
                                                "id": "if_type",
                                                "title": "Variable type.",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select",
                                                "required": true
                                            },
                                            {
                                                "name": "variable1",
                                                "label": "variable1",
                                                "id": "variable1",
                                                "title": "Enter value",
                                                "category": "Settings",
                                                "type": "text",
                                                "fieldType": "input",
                                                "required": true
                                            },
                                            {
                                                "options": {
                                                    "less than": "<",
                                                    "greater than or equals": ">=",
                                                    "equal to": "==",
                                                    "less than or equals": "<=",
                                                    "not equal to": "!=",
                                                    "greater than": ">"
                                                },
                                                "name": "comparator",
                                                "label": "Compare this",
                                                "id": "if_type",
                                                "title": "Operation type.",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select",
                                                "required": true
                                            },
                                            {
                                                "name": "variable2",
                                                "label": "variable2",
                                                "id": "variable2",
                                                "title": "Enter value",
                                                "category": "Settings",
                                                "type": "text",
                                                "fieldType": "input",
                                                "required": true
                                            }
                                        ],
                                        "material_icon_color": "blue",
                                        "displayname": "If Condition",
                                        "material_icon_bg_color": "bg_blue",
                                        "name": "If Condition",
                                        "material_icon_img": "storage",
                                        "company": "Appup.com",
                                        "id": "If_Condition_Step",
                                        "category": "Utilities",
                                        "info": "Condition Step"
                                    },
                                    {
                                        "name": "Ajax",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/common/jsonio.png",
                                        "info": "Perform an asynchronous HTTP request within workflow.",
                                        "help": "Perform an asynchronous HTTP request within workflow.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "type": {
                                            "name": "ajax"
                                        },
                                        "branches": "failure,success",
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.JSONNode",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "URL:",
                                                "category": "Settings",
                                                "required": "required",
                                                "name": "url",
                                                "title": "Please enter a valid HTTP URL",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Output Variable",
                                                "category": "More Settings",
                                                "required": "required",
                                                "name": "var",
                                                "title": "Please enter a variable to store output",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Method type:",
                                                "required": "No",
                                                "category": "Settings",
                                                "name": "method",
                                                "title": "Select the appropriate type for accessing the URL.",
                                                "options": {
                                                    "*GET": "get",
                                                    "POST": "post",
                                                    "PUT": "put",
                                                    "DELETE": "delete"
                                                },
                                                "fieldType": "select",
                                                "type": "select"
                                            },
                                            {
                                                "label": "Post Data",
                                                "category": "Settings",
                                                "required": "required",
                                                "name": "data",
                                                "title": "Please enter the data variable you want to send",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Params",
                                                "category": "Settings",
                                                "name": "data_params",
                                                "type": "grid",
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "name": "name",
                                                        "required": "required",
                                                        "title": "Please enter keyname",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "name": "value",
                                                        "required": "required",
                                                        "title": "Please enter value",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ],
                                                "defaultvalues": [
                                                    {
                                                        "keyName": "name",
                                                        "valueName": "value"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Headers",
                                                "category": "Headers",
                                                "name": "headers",
                                                "type": "grid",
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "name": "name",
                                                        "required": "required",
                                                        "title": "Please enter keyname",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "name": "value",
                                                        "required": "required",
                                                        "title": "Please enter value",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ],
                                                "defaultvalues": [
                                                    {
                                                        "keyName": "Accept",
                                                        "valueName": "text/plain"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Perform an asynchronous HTTP request within workflow.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Alert",
                                        "thumbnail": "json/nodes/images/Announcement.png",
                                        "icon": "json/nodes/icons/common/push.png",
                                        "info": "Enter title and message in the alert.",
                                        "help": "Enter title and message in the alert.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "alert"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Title",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "title",
                                                "id": "title",
                                                "title": "Enter title to alert.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Message",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "message",
                                                "id": "message",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter message to alert.",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Enter title and message in the alert.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Delete Cookie",
                                        "thumbnail": "json/nodes/images/new/sql-delete-node.png",
                                        "icon": "json/nodes/icons/new/sql-delete.png",
                                        "info": "Deletes cookie value of given key",
                                        "help": "Deletes cookie value of given key.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "delete_cookie"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Cookie Key",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "cookie_key",
                                                "id": "cookie_key",
                                                "title": "Enter cookie key to delete.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deletes cookie value of given key.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Delete Local Storage",
                                        "thumbnail": "json/nodes/images/new/sql-delete-node.png",
                                        "icon": "json/nodes/icons/new/sql-delete.png",
                                        "info": "Deletes local storage value of given key",
                                        "help": "Deletes local storage value of given key.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "delete_local_storage"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Local Storage Key",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "ls_key",
                                                "id": "ls_key",
                                                "title": "Enter local storage key to delete.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deletes local storage value of given key.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Deserialize",
                                        "thumbnail": "json/nodes/images/Variables.png",
                                        "icon": "json/nodes/icons/common/addtask.png",
                                        "info": "Deserializes form fields.",
                                        "help": "Deserializes form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "deserialize_form"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Data",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "data",
                                                "id": "data",
                                                "title": "Enter data to deserialize",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Enter form id.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Deserializes form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Trigger Event",
                                        "thumbnail": "json/nodes/images/Announcement.png",
                                        "icon": "json/nodes/icons/common/push.png",
                                        "info": "Trigger browser events from handler.",
                                        "help": "Trigger browser events from handler.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "emit-event"
                                        },
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Reference",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "ref",
                                                "id": "ref",
                                                "title": "Enter reference to event.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Event Name",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "event_name",
                                                "id": "event-name",
                                                "title": "Enter name of the event.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Params",
                                                "category": "Info",
                                                "name": "data",
                                                "type": "grid",
                                                "required": "true",
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "name": "name",
                                                        "required": "required",
                                                        "title": "Please enter keyname",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "name": "value",
                                                        "required": "required",
                                                        "title": "Please enter value",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Trigger browser events from handler",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Get Cookie",
                                        "thumbnail": "json/nodes/images/new/sql-import-node.png",
                                        "icon": "json/nodes/icons/new/sql-import.png",
                                        "info": "Gets cookie from browser based on given parameters.",
                                        "help": "Gets cookie from browser based on given parameters.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "get_cookie"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.NewCondition",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Method",
                                                "category": "Info",
                                                "name": "method",
                                                "id": "method",
                                                "title": "Enter method",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Key",
                                                "category": "Info",
                                                "name": "key",
                                                "id": "key",
                                                "title": "Enter key",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Variable",
                                                "category": "Info",
                                                "name": "var",
                                                "id": "var",
                                                "title": "Enter variable",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Headers",
                                                "category": "Info",
                                                "name": "headers",
                                                "id": "headers",
                                                "title": "Enter headers",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Get cookie based on given parameters.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "JS code",
                                        "thumbnail": "json/nodes/images/common/jsonio.png",
                                        "icon": "json/nodes/icons/crm/addnote.png",
                                        "info": "Enter js code to execute.",
                                        "help": "Enter js code to execute.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "js"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Code",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "code",
                                                "id": "code",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter js code to execute.",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "Enter js code to execute.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Modal",
                                        "thumbnail": "json/nodes/images/Variables.png",
                                        "icon": "json/nodes/icons/crm/notify.png",
                                        "info": "Enter title and message in the modal.",
                                        "help": "Enter title and message in the modal.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "modal"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Title",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "modal-title",
                                                "id": "modal-title",
                                                "title": "Enter title to modal.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Body",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "body",
                                                "id": "body",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter body to modal.",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "OK button title",
                                                "category": "Info",
                                                "name": "modal-ok-title",
                                                "id": "modal-ok-title",
                                                "title": "Enter title to ok button.",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "OK"
                                            },
                                            {
                                                "label": "Cancel button title",
                                                "category": "Info",
                                                "name": "modal-cancel-title",
                                                "id": "modal-cancel-title",
                                                "title": "Enter title to cancel button.",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "Cancel2"
                                            },
                                            {
                                                "options": {
                                                    "False": "false",
                                                    "True": "true"
                                                },
                                                "name": "modal-no-close-on-esc",
                                                "label": "Close on ESC?",
                                                "id": "modal-no-close-on-esc",
                                                "title": "Select option on esc",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select"
                                            },
                                            {
                                                "options": {
                                                    "False": "false",
                                                    "True": "true"
                                                },
                                                "name": "modal-no-close-on-backdrop",
                                                "label": "Close on backdrop?",
                                                "id": "modal-no-close-on-backdrop",
                                                "title": "Select option on backdrop",
                                                "category": "Settings",
                                                "type": "select",
                                                "fieldType": "select"
                                            },
                                            {
                                                "label": "Modal Config",
                                                "category": "Settings",
                                                "name": "config",
                                                "id": "modal-config",
                                                "cols": "75",
                                                "rows": "13",
                                                "title": "Enter modal config",
                                                "fieldType": "textarea",
                                                "type": "textarea"
                                            },
                                            {
                                                "label": "OK variant",
                                                "category": "Info",
                                                "name": "modal-ok-variant",
                                                "id": "modal-ok-variant",
                                                "title": "Enter variant for OK button",
                                                "fieldType": "input",
                                                "type": "text",
                                                "value": "danger"
                                            },
                                            {
                                                "label": "Enter title and message in the modal.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Navigate",
                                        "thumbnail": "json/nodes/images/common/url.png",
                                        "icon": "json/nodes/icons/common/transfer.png",
                                        "info": "Enter path to navigate.",
                                        "help": "Enter path to navigate.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "navigate"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Path",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "url",
                                                "id": "url",
                                                "title": "Enter path to navigate.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Enter path to navigate.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Serialize",
                                        "thumbnail": "json/nodes/images/crm/status.png",
                                        "icon": "json/nodes/icons/new/sql-update.png",
                                        "info": "Serializes form fields.",
                                        "help": "Serializes form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "serialize"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Utilities",
                                        "ui": [
                                            {
                                                "label": "Output",
                                                "required": "required",
                                                "category": "Info",
                                                "name": "output",
                                                "id": "output",
                                                "title": "Enter output variable to hold serialized data",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Enter form id.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Serializes form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Set Cookie",
                                        "thumbnail": "json/nodes/images/crm/status.png",
                                        "icon": "json/nodes/icons/new/sql-insert.png",
                                        "info": "Saves given key-value pairs in browser cookies.",
                                        "help": "Saves given key-value pairs in browser cookies.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "set_cookie"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.NewCondition",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Cookie",
                                                "category": "Settings",
                                                "name": "cookie_key_grid",
                                                "type": "grid",
                                                "style": {
                                                    "overflow-y": "scroll"
                                                },
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "id": "cookie_key",
                                                        "class": "cookie_key",
                                                        "name": "cookie_key",
                                                        "title": "Enter cookie key name.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "name": "cookie_value",
                                                        "title": "Enter cookie value.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Saves given key-value pairs in browser cookies.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Set Local Storage",
                                        "thumbnail": "json/nodes/images/new/sql-insert-node.png",
                                        "icon": "json/nodes/icons/new/sql-insert.png",
                                        "info": "Saves given key-value pairs in browser local storage.",
                                        "help": "Saves given key-value pairs in browser local storage.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success",
                                        "type": {
                                            "name": "set_local_storage"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.NewCondition",
                                        "category": "Database",
                                        "ui": [
                                            {
                                                "label": "Local Storage",
                                                "category": "Settings",
                                                "name": "ls_key_grid",
                                                "type": "grid",
                                                "style": {
                                                    "overflow-y": "scroll"
                                                },
                                                "ui": [
                                                    {
                                                        "label": "Key",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "id": "ls_key",
                                                        "class": "ls_key",
                                                        "name": "ls_key",
                                                        "title": "Enter local storage key name.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    },
                                                    {
                                                        "label": "Value",
                                                        "required": "required",
                                                        "category": "Settings",
                                                        "name": "ls_value",
                                                        "title": "Enter local storage value.",
                                                        "fieldType": "input",
                                                        "type": "text"
                                                    }
                                                ]
                                            },
                                            {
                                                "label": "Saves given key-value pairs in browser local storage.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    },
                                    {
                                        "thumbnail": "json/nodes/images/Condition.png",
                                        "author": "Appup Developer",
                                        "icon": "json/nodes/icons/common/SetProperty.png",
                                        "language": "en",
                                        "branches": "success,failure",
                                        "type": {
                                            "name": "set_var"
                                        },
                                        "material_icon": "settings",
                                        "workflow_tasklet_class_name": "com.appup.workflows.core.steps.SetVarStepExecutor",
                                        "help": "Step Help",
                                        "ui": [
                                            {
                                                "name": "output",
                                                "label": "Output Variable",
                                                "id": "output",
                                                "title": "Enter variable name to hold value",
                                                "category": "Settings",
                                                "type": "text",
                                                "fieldType": "input",
                                                "required": true
                                            },
                                            {
                                                "name": "value",
                                                "label": "Value",
                                                "id": "value",
                                                "title": "Enter Value",
                                                "category": "Settings",
                                                "type": "text",
                                                "fieldType": "input",
                                                "required": true
                                            },
                                            {
                                                "name": "help",
                                                "label": "Set the value of a specific variable",
                                                "id": "help",
                                                "title": "Help",
                                                "category": "help",
                                                "type": "label",
                                                "fieldType": "label",
                                                "required": true
                                            }
                                        ],
                                        "material_icon_color": "blue",
                                        "displayname": "Set var",
                                        "material_icon_bg_color": "bg_blue",
                                        "name": "Set var",
                                        "material_icon_img": "storage",
                                        "company": "Appup.com",
                                        "id": "set-var",
                                        "category": "Utilities",
                                        "info": "Step info"
                                    },
                                    {
                                        "name": "Validate",
                                        "thumbnail": "json/nodes/images/Condition.png",
                                        "icon": "json/nodes/icons/common/SetProperty.png",
                                        "info": "Validates form fields.",
                                        "help": "Validates form fields.",
                                        "author": "Naresh",
                                        "company": "Appup",
                                        "language": "en",
                                        "branches": "success,failure",
                                        "type": {
                                            "name": "validate"
                                        },
                                        "workflow_tasklet_class_name": "com.campaignio.tasklets.agile.Score",
                                        "category": "Developers",
                                        "ui": [
                                            {
                                                "label": "Select form",
                                                "category": "Info",
                                                "name": "form_id",
                                                "title": "Select form to validate.",
                                                "fieldType": "input",
                                                "type": "text"
                                            },
                                            {
                                                "label": "Validates form fields.",
                                                "category": "Help",
                                                "fieldType": "label",
                                                "type": "label"
                                            }
                                        ]
                                    }
                                ]
                            }  
                }
            }
        }
    </script>