<template>
<ac-menubar :left_controls="lc" nav_bgcolor="bg-info"></ac-menubar>
</template>
​
<script>
export default {
  data() {
    return { 
      lc: [
        {
          type: "link",
          label: "Dashboard",
          icon: "Dashboard",
          link_to: "#alert",
          activeWhen: ["/dashboard"]
        },
        {
          type: "link",
          label: "Pages",
          icon: "pages",
          link_to: "#alert",
          activeWhen: ["/pages"]
        },
        {
          type: "link",
          label: "Reports",
          icon: "Reports",
          link_to: "#alert",
          activeWhen: ["/Reports"]
        },
        {
          type: "link",
          label: "Blocks",
          icon: "dashboard",
          link_to: "#code",
          activeWhen: ["/Blocks"]
        }
      ]
    };
  }
};
</script>
​
<style>
</style>
