<template>
    <ac-dynamic-chart :chart_data="data"
    @slide_click="eventraised"
    @page_change="eventraised"
     @navigation_click="eventraised" :attributes="attrs" count="2"></ac-dynamic-chart>
</template>
<script>
export default {

data(){
        return{
            attrs:{"navigationEnabled" : true},
			data:
			[{
		"title": "Earned Leave",
		"type": "pie",
		"data": [{
			"name": "granted",
			"values": [5]
		}, {
			"name": "availed",
			"values": [15]
		}, {
			"name": "balance",
			"values": [12]
		}],
		"config": {
			"subtitle": "testing",
			"tooltip": true,
			"legend": false
		}
	},
	{
		"title": "Happy Leave",
		"type": "column",
		"data": [{
			"name": "granted",
			"values": [5]
		}, {
			"name": "availed",
			"values": [5]
		}, {
			"name": "balance",
			"values": [5]
		}],
		"config": {
			"subtitle": "testing",
			"tooltip": true,
			"legend": false
		}
	},
	{
		"title": "No Leave",
		"type": "line",
		"data": [{
			"name": "granted",
			"values": [2]
		}, {
			"name": "availed",
			"values": [7]
		}, {
			"name": "balance",
			"values": [5]
		}],
		"config": {
			"subtitle": "testing",
			"tooltip": true,
			"legend": false
		}
	},
	{
		"title": "Sick Leave",
		"type": "pie",
		"data": [{
			"name": "granted",
			"values": [8]
		}, {
			"name": "availed",
			"values": [5]
		}, {
			"name": "balance",
			"values": [3]
		}],
		"config": {
			"subtitle": "testing",
			"tooltip": true,
			"legend": false
		}
	},
	{
		"title": "Earned Leave",
		"type": "pie",
		"data": [{
			"name": "granted",
			"values": [5]
		}, {
			"name": "availed",
			"values": [7]
		}, {
			"name": "balance",
			"values": [2]
		}],
		"config": {
			"subtitle": "testing",
			"tooltip": true,
			"legend": false
		}
	}
]

        }
    },
    methods:{
        eventraised(data){
            console.log("ok",data)
        }
    }
}
</script>