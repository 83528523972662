<template>
<div>
    <ac-slideout-panel  cname="ac-inline-form-edit" :form-data="formData" @onEnter="updateValue" @onClose="closingSlide"></ac-slideout-panel>
</div>
</template>

<script>
    export default {
        data() {
            return {
                formData:[
                    {
                        "type": "text",
                        "value": "sjgjdjfkg",
                        "placeholder": "Enter",
                        "label": "Name",
                        "validateObj": { required: true },
                        "display": true
                    },
                    {
                        "type": "text",
                        "value": "jhsdfjhsjdfk@gmail.com",
                        "placeholder": "Enter",
                        "label": "Email",
                        "validateObj": { required: true, email: true },
                        "display": true
                    },
                    {
                        "type": "text",
                        "value": "hafjhsjk",
                        "placeholder": "Enter",
                        "label": "Last name",
                        "validateObj": { required: true },
                        "display": true
                    },
                    {
                        "type": "select",
                        "value": "a",
                        "label": "Name",
                        "options": [
                            { value: "", text: 'Please select an option' },
                            { value: 'a', text: 'This is First option' },
                            { value: 'b', text: 'Selected Option' },
                            { value: 'c', text: 'This is an option with object value' }
                        ],
                        "display": true
                    },
                    {
                        "type": "date",
                        "value": "22/11/2019",
                        "label": "Date",
                        "display": true
                    }
                ]
            }
        },
        methods: {
            updateValue: function (data) {
                alert(data.value);
            },
            closingSlide: function () {
                alert("Closed");
            }
        }
    };
</script>

<style>
</style>