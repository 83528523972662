<template>
  <div>
    <ac-dual-list
      v-model="dualValues"
      :left_items="[
              { label: 'Option 1', value: '01' },
              { label: 'Option 2', value: '02' },
            ]" 
      :collection_props="collection"
      :dropdown_options_handler="dropdown_options_handler"
      :dropdown_button_options="dropdown_button_options"
      show_dropdown_icons="true"
      @change="event_test"
      @dropdown_click="event_test"
      @deleteAndInsert="deleteInsert"
      key_value="id"
      key_label="body"
    >
      <template v-slot:empty_state><p class="text-center"></p></template>
    </ac-dual-list>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      dualValues: [],
      dropdown_options_handler: [
        {
          label: "Edit",
          type: "edit",
          icon: "fas fa-pencil-alt"
        },
        {
          label: "Delete",
          type: "delete",
          icon: "fas fa-trash-alt"
        }
      ],
      dropdown_button_options: {
        icon: "fas fa-ellipsis-v",
        variant: "outline-secondary",
        size: "sm",
        right: true
      },
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {},
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    },
    deleteInsert(object) {
      console.log("object is", object);
    }
  }
};
</script>

<style>
</style>
