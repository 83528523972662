<template>
    <ac-mylist :listgroups="listgroups" />
</template>

<script>
export default {
data() {
    return {
        listgroups: [
            {listname:"Start Date", listvalue:"June 12, 2019"},
            {listname:"End Date", listvalue:"June 27, 2019"},
            {listname:"Start Budget", listvalue:"$5500"}
        ]
    }
}
}
</script>

<style>

</style>
