<template>
  <div>
    <ac-wizard
      :steps="steps"
    >
      <template #step_1>
        <b-card title="step 1">
          <p>
            Step 1 content
          </p>
        </b-card>
      </template>

      <template #step_2>
        <b-card title="step 2">
          <p>
            Input is required to continue
          </p>

          <b-input v-model="input"></b-input>
        </b-card>
      </template>

      <template #step_3>
        <b-card title="step 3">
          <p>step 3 content</p>
        </b-card>
      </template>
    </ac-wizard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
      steps: [
        {
          html: '<div class="header-circle">1</div>',
          title: 'step 1',
          description: 'step 1 description',
          block: 'step_1',
          active: true,
          completed: false,
        },

        {
          html: '<div class="header-circle">2</div>',
          title: 'step 2',
          description: 'step 2 description',
          block: 'step_2',
          active: true,
          completed: false,
          validator: () => this.input.length > 0,
        },

        {
          html: '<div class="header-circle">3</div>',
          title: 'step 3',
          description: 'step 3 description',
          block: 'step_3',
          active: true,
          completed: false,
        },
      ],
    }
  },
}
</script>

<style>
  .header-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darkblue;
    color: white;
  }
</style>
