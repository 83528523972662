<template>
  <ac-card-collection
    ref="ac_collection"
    :url="collection.url"
    :method="collection.method"
    :ac_cursor="{}"
    :params="collection.params"
    :loading_image="collection.loading_image"
    :sort_key="collection.sort_key"
    :show_dropdown="true"
    :dropdown_options="dropdown.options"
    :dropdown_button_options="dropdown.button_options"
    :show_dropdown_icons="true"
    responsive_class="col-lg-4 col-md-6 col-xs-12 col-sm-12 col-xl-4"
    :show_searchbar="show_searchbar"
    :search_key="search_key"
    searchbar_placeholder="Search here"
    :pagination_type="pagination_type"
    :data_count_url="data_count_url"
    :count_key="count_key"
    :limit="limit"
    :offset_key="offset_key"
    :limit_key="limit_key"
  >
    <template #header>
      <header class="border p-2 mb-2">Ac collection header</header>
    </template>

    <!-- <template #body="{ data }">
      <b-row>
        <b-col v-for="item in data" :key="item.id" class="ac__item" :lg="6">
          <b-card :title="item.name" class="mb-2">
            <b-card-text>{{ item.body }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </template>-->

    <template #card_header="{ item }">
      <div class="card-header">{{item.id}} - {{item.email}}</div>
    </template>
    <template #card_body="{ item }">
      <div class="card-body">{{item.body}}</div>
    </template>
    <template #card_footer="{ item }">
      <div class="card-footer">{{item.postId}}</div>
    </template>
    <template #empty_state>
      <p>No data</p>
    </template>
    <template #footer>
      <footer class="border p-2 mt-2">ac collection footer</footer>
    </template>
  </ac-card-collection>
</template>

<script>
export default {
  data() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {},
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt",
            handler: "hello"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt",
            handler: "hello"
          }
        ],
        button_options: {
          icon: "fas fa-ellipsis-h",
          variant: "bg-transparent",
          size: "sm",
          right: true
        }
      },
      show_searchbar: true,
      search_key: "email",
      pagination_type: "pagination",
      data_count_url: "https://api.myjson.com/bins/mcwsk",
      count_key: "count",
      limit: 5,
      offset_key: "_start",
      limit_key: "_limit"
    };
  },
  mounted() {
    this.$refs.ac_collection.set_ajax();
    console.log(this.$refs);
  }
};
</script>

<style>
</style>
