<template>
  <div>
    <ac-tags-inline
      :url="fetchurl"
      :search_url="searchurl"
      :search_key="search_key"
      :allow_duplicate="allow_duplicate1"
      @add="added"
      @removed="deleted"
      :key_name="key_name"
      :key_value="key_value"
    >
      <template #body="{item}">
        <b-badge class="m-1 ml-2" variant="secondary">{{item.name}}</b-badge>
      </template>
    </ac-tags-inline>
    <br />
    <ac-tags-inline
      :url="fetchurl"
      :search_url="searchurl"
      :search_key="search_key"
      :allow_duplicate="allow_duplicate2"
      @add="added"
      @removed="deleted"
      :key_name="key_name"
      :key_value="key_value"
    >
      <template #body="{item}">
        <ac-gravatar
          class="m-1"
          :avatar_props="{ size: 35, rounded: true ,username:item.name,src:``}"
        />
      </template>
    </ac-tags-inline>
    <br />
    <ac-tags-inline
      :url="fetchurl"
      type="from"
      @type="emit"
      :search_url="searchurl"
      :search_key="search_key"
      :allow_duplicate="allow_duplicate1"
      @add="added"
      @removed="deleted"
      :key_name="key_name"
      :key_value="key_value"
    >
      <template #body="{item}">
        <div>
          <span class="d-flex align-items-center mb-2">
            <i class="fas fa-file text-muted mr-3" style="font-size: 16px;"></i>
            <p class="text-m mb-0 ml-1">
              <a v-bind:href="item.attachment_file" class="text-secondary" v-html="item.name"></a>
            </p>
          </span>
        </div>
      </template>
    </ac-tags-inline>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      fetchurl: "https://api.myjson.com/bins/d5xij",
      searchurl: "https://support.appup.cloud/d1/bulk/label",
      search_key: "where=name like",
      key_name: "name",
      key_value:"id",
      allow_duplicate1: false,
      allow_duplicate2: true,
    };
  },
  methods: {
    added: function(data) {
      console.log(data);
    },
    deleted: function(item) {
      console.log("item", item);
    },
    emit: function(event) {
      console.log("emited", event);
    }
  }
};
</script>
