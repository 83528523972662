<template>
  <div>
    <div class="fluid-container">
      <ac-radio id="radio-group-1" v-model="selected" name="radio-options" value="A">Option A</ac-radio>
      <ac-radio v-model="selected" name="some-radios" value="B">Option B</ac-radio>
    </div>
    <div class="mt-3">
      Selected:
      <strong>{{ selected }}</strong>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: "A"
    };
  }
};
</script>