<template>
  <ac-timeline :items="items" />
</template>

<script>
export default {
  data() {
    return {
      items: [{
        id: 7, element_status: '', icon_class: 'fas fa-sign-out-alt', icon_status: 'success', title: 'DELIVERY', controls: [], created: '', body: 'To plan, created by Insurer', action_needed: 'pulse_wrap',
      }, {
        id: 7, element_status: 'main_element', icon_class: 'fas fa-sign-out-alt', icon_status: 'success', title: 'DELIVERY', controls: [], created: '', body: 'To plan, created by Insurer', action_needed: 'pulse_wrap',
      }, {
        id: 7, element_status: 'main_element', icon_class: 'fas fa-sign-out-alt', icon_status: 'success', title: 'DELIVERY', controls: [], created: 'Monday Oct. 4 - 10:00', body: 'In 16 days, created by you',
      }, {
        id: 6, icon_class: 'fas fa-wrench', icon_status: 'planning', title: 'REPAIR END', controls: [], created: 'Monday Oct. 3 - 8:00', body: 'In 15 days, expected in Planning',
      }, {
        id: 5, icon_class: 'fas fa-wrench', icon_status: 'planning', title: 'REPAIR START', controls: [], created: 'Monday Sept. 30 - 10:00', body: 'In 12 days, expected in Planning',
      }, {
        id: 4, icon_class: 'fas fa-sign-in-alt', icon_status: 'success', title: 'DROP-OFF', controls: [], created: 'Friday Sept. 24 - 14:30', body: 'In 3 days, created by you',
      }, {
        id: 3, element_day_marker: 'today', icon_class: 'fas fa-arrow-up', icon_status: 'warning', title: '', controls: [], created: '', body: '',
      }, {
        id: 2, element_day_marker: 'past', icon_class: 'fas fa-edit', icon_status: '', title: 'ESTIMATION', controls: [], created: 'Tuesday Sept. 20 - 15:00', body: 'Follow-up required, self-booking', action_needed: 'pulse_wrap',
      }, {
        id: 2, element_status: 'selected_past', element_day_marker: 'past', icon_class: 'fas fa-edit', icon_status: '', title: 'ESTIMATION', controls: [], created: 'Tuesday Sept. 20 - 15:00', body: 'Follow-up required, self-booking', action_needed: 'pulse_wrap',
      }, {
        id: 2, element_day_marker: 'past', icon_class: 'fas fa-edit', icon_status: '', title: 'ESTIMATION', controls: [], created: 'Tuesday Sept. 20 - 15:00', body: '4 days ago, self-booking',
      }, {
        id: 1, element_day_marker: 'past', icon_class: 'fas fa-edit', icon_status: '', text_status: 'cancelled', title: 'ESTIMATION', controls: [], created: 'Monday Sept. 16 - 15:00', body: 'Cancelled, created by Desjardins',
      }, {
        id: 1, element_day_marker: 'past', icon_class: 'fas fa-edit', icon_status: '', text_status: 'cancelled', title: 'ESTIMATION', controls: [], created: '', body: 'Cancelled, created by Desjardins',
      }, {
        id: 7, element_status: 'selected_past', icon_class: 'fas fa-sign-out-alt', icon_status: 'past', title: 'ESTIMATION', controls: [], created: 'Monday Oct. 4 - 10:00', body: '16 days ago, created by you',
      }],
    }
  },
}
</script>

<style>

</style>
