<template>
    <ac-notifications
              :show_mark_all_btn="true"
              :show_see_all_btn="true"
              url="https://jsonplaceholder.typicode.com/todos?userId=1"
              mark_all_btn_url="https://jsonplaceholder.typicode.com/posts"
              read_key="completed"
              icon="fa fa-bell"
            >
            <template #custom="{ data }">
              {{data}}
            </template>
              <template #body="{ item }">
                {{ item.title }}
              </template>
            </ac-notifications>
</template>

<script>
export default {
  data: function () {
      return {
          
      }
  }
}
</script>

<style>

</style>
