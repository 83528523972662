<template>
  <ac-chart
    :data="data"
    type="line"
    title="linechart"
    :config="config"
  ></ac-chart>
</template>

<script>
export default {
    data(){
        return{
            data:'[{"name": "Installation","values": "[107, 31, 635, 203, 2]"}, {"name": "Manufacturing","values": "[133, 156, 947, 408, 6]"}, {"name": "Sales & Distribution","values": [814, 841, 3714, 727, 31]}, {"name": "Project Development","values": [1216, 1001, 4436, 738, 40]}]',
            config:{subtitle:"testing",xtitle:"this is x testing",ytitle:"this is y testing", xcategory:["first","second","third","fourth","fifth"], ycategory:["first","second","third","fourth","fifth"],tooltip:true,legend:false},
        }
    }
};
</script>

<style>
</style>
