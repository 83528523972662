<template>
  <div class="row">
    <div class="col-6">
      <h4>With Data</h4>
      <br>
      <ac-simple-card
        :data="example_card_data.data"
        :card_title="example_card_data.card_title"
        :card_text="example_card_data.card_text"
        checkbox_select_key="is_active"
        card_header="id"
        card_footer="email"
        img="img"
        :footer_slot="true"
        :header_slot="true"
        :bulk="example_card_data.bulk"
        :dropdown_options="dropdown.options"
        url=""
        :card_class="card_class"
        :dropdown_button_options = "dropdown.button_options"
        @hover="event_test"
        @click="event_test"
        :card_subtitle="example_card_data.card_subtitle"
      >
        <template #hover>
          <div class="hover-div">HOVER</div>
        </template>
        <template #header>
          <div class>headers</div>
        </template>
        <template slot="body"></template>
        <template #footer>
          <div class>footer</div>
        </template>
      </ac-simple-card>
    </div>
    <div class="col-6">
      <!--with url -->
      <h5>With URL</h5>
      <br>
      <ac-simple-card
        :bulk="example_card_data.bulk"
        :dropdown_options="dropdown.options"
        url="https://api.myjson.com/bins/71929"
        img="img"
        :data="example_card_data.data"
        :card_title="example_card_data.card_title"
        :card_text="example_card_data.card_text"
        @hover="event_test"
        @click="event_test"
        :footer_slot="true"
        :header_slot="true"
      >
        <!-- <template #hover>
          <div class="hover-div">HOVER</div>
        </template>-->
        <template #header>
          <div class>headers</div>
        </template>
        <template slot="body"></template>
        <template #footer>
          <div class>footer</div>
        </template>
      </ac-simple-card>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      card_class: "c0l-md-12 md-12",
      example_card_data: {
        data: {
          id: 1,
          img: "https://picsum.photos/300/300/?image=41",
          title: "Title of card",
          body:
            "est natus enim nihil est dolore omnis voluptatem numquam et omnis occaecati quod ullam at voluptatem error expedita pariatur nihil sint nostrum voluptatem reiciendis e",
          is_active: true
        },
        card_title: "title",
        card_text: "body",
        bulk: true,
        card_subtitle: "id"
      },
      dropdown: {
        options: [
          // {
          //   label: "Edit",
          //   type: "edit",
          //   icon: "fas fa-pencil-alt",
          //   handler: "hello"
          // },
          // {
          //   label: "Delete",
          //   type: "delete",
          //   icon: "fas fa-trash-alt",
          //   handler: "hello"
          // }
        ],
        button_options: {
          icon: "far fa-ellipsis-h",
          variant: "bg-transparent p-0",
          size: "sm",
          right: true
        }
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>