<template>
  <div>
    <ac-view
      :options="view_options"
      :header_classes="['p-2', 'mb-2']"
      header_label="ac view"
    >
    <!-- in the above tag border class removed -->
      <template #ac-collection-card>
        <ac-collection-card
          ref="ac_collection_card"
          :url="collection.url"
          :method="collection.method"
          :params="collection.params"
          :loading_image="collection.loading_image"
          :sort_key="collection.sort_key"
          :bulk="true"
          :card_links="card_links"
          :card_options="{
                    'is_delete': true
                  }"
          :ac_cursor="collection.ac_cursor"
          :card_img_hover="hover_html"
          card_header
          align="vertical"
          card_title="name"
          card_subtitle="email"
          card_body="body"
          card_footer="name"
          card_img="image"
          card_body_html="p"
        />
      </template>

      <template #ac-collection-table>
        <ac-collection-table
          :url="collection.url"
          :method="collection.method"
          :params="collection.params"
          :loading_image="collection.loading_image"
          :sort_key="collection.sort_key"
          :columns="table_data.columns"
          :dropdown="table_data.dropdown"
          :dropdown_options="dropdown.options"
          :dropdown_button_options="dropdown.button_options"
          :bulk="table_data.bulk"
          :ac_cursor="collection.ac_cursor"
          @dropdown_click="event_test"
        />
      </template>
    </ac-view>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      view_options: [
        {
          icon: "fas fa-th",
          ref: "ac-collection-card",
          selected: true
        },
        {
          icon: "fas fa-list-ul",
          ref: "ac-collection-table"
        }
      ],
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      },
      hover_html:
        '<div class="bg-light text-success w-100 h-100 d-flex justify-content-center align-items-center">HOVER</div>',
      card_links: [
        {
          label: "First",
          href: "/first",
          variant: "primary"
        },
        {
          label: "Second",
          href: "/second",
          variant: "success"
        }
      ],
      table_data: {
        bulk: true,
        dropdown: true,
        columns: [
          {
            label: "Id",
            sortable: true,
            key: "id"
          },
          {
            label: "Name",
            sortable: false,
            key: "name"
          }
        ]
      },
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt"
          }
        ],
        button_options: {
          icon: "fas fa-ellipsis-v",
          variant: "outline-secondary",
          size: "sm",
          right: true
        }
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

<style>
</style>
