<template>
  <ac-action-navbar :columns="items">
    <template slot="12">Example</template>
  </ac-action-navbar>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          key: 12,
          __slot__: "<h1>item 1</h1>",
          class: "col-2"
        },
        {
          key: 11,
          __slot__: "<h2>item 2</h2>",
          class: "col-3"
        },
        {
          key: 10,
          __slot__: "<h3>item 3</h3>",
          class: "col-2"
        },
        {
          key: 9,
          __slot__: "<button class='btn btn-primary'>Button</b-button>",
          class: "col-3"
        },
        {
          key: 18,
          __slot__: "<h5>item 5</h5>",
          class: "col-2"
        }
      ]
    };
  }
};
</script>

<style>
</style>
