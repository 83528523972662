<template>
  <div>
    <h4>Basic Usage</h4>
    <br>
    <ac-multiselect v-model="valueBasic" :options="options"/>
    <hr>
    <h4>Single select</h4>
    <br>
    <div>
      <ac-multiselect
        v-model="valueSingle"
        :options="options"
        :searchable="false"
        placeholder="Pick a value"
      ></ac-multiselect>
      <pre class="language-json"><code>{{ valueSingle  }}</code></pre>
    </div>
    <br>
    <hr>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valueBasic: null,
      valueSingle: null,
      options: [
        { name: "Vue.js", language: "JavaScript" },
        { name: "Rails", language: "Ruby" },
        { name: "Sinatra", language: "Ruby" },
        { name: "Laravel", language: "PHP", $isDisabled: true },
        { name: "Phoenix", language: "Elixir" }
      ]
    };
  }
};
</script>