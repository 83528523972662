<template>
  <ac-gantt-chart :tasks="tasks" :options="options" :show_toolbar="true" toolbar_title="My custom title">
  </ac-gantt-chart>
</template>

<script>
export default {
  data() {
    return {
      tasks: [
        {
          id: 1,
          label: "Make some noise",
          user:
            '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
          start: "2018-09-10",
          duration: 15 * 24 * 60 * 60 * 1000,
          progress: 85,
          type: "project"
          //collapsed: true,
        },
        {
          id: 2,
          label: "With great power comes great responsibility",
          user:
            '<a href="https://www.google.com/search?q=Peter+Parker" target="_blank" style="color:#0077c0;">Peter Parker</a>',
          parentId: 1,
          start: "2018-09-15",
          duration: 4 * 24 * 60 * 60 * 1000,
          progress: 50,
          type: "milestone",
          collapsed: true,
          style: {
            base: {
              fill: "#1EBC61",
              stroke: "#0EAC51"
            }
            /*'tree-row-bar': {
              fill: '#1EBC61',
              stroke: '#0EAC51'
            },
            'tree-row-bar-polygon': {
              stroke: '#0EAC51'
            }*/
          }
        },
        {
          id: 3,
          label: "Courage is being scared to death, but saddling up anyway.",
          user:
            '<a href="https://www.google.com/search?q=John+Wayne" target="_blank" style="color:#0077c0;">John Wayne</a>',
          parentId: 2,
          start: "2018-09-22",
          duration: 2 * 24 * 60 * 60 * 1000,
          progress: 100,
          type: "task"
        },
        {
          id: 4,
          label: "Put that toy AWAY!",
          user:
            '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
          start: "2018-09-27",
          duration: 2 * 24 * 60 * 60 * 1000,
          progress: 50,
          type: "task",
          dependentOn: [3]
        }
      ],

      options: {
        maxRows: 100,
        maxHeight: 300,
        row: {
          height: 24
        },
        calendar: {
          hour: {
            display: false
          }
        },
        chart: {
          progress: {
            bar: false
          },
          expander: {
            display: true
          }
        },
        taskList: {
          expander: {
            straight: false
          },
          columns: [
            {
              id: 1,
              label: "ID",
              value: "id",
              width: 40
            },
            {
              id: 2,
              label: "Description",
              value: "label",
              width: 200,
              expander: true,
              html: true,
              events: {
                click({ data, column }) {
                  alert("description clicked!\n" + data.label);
                }
              }
            },
            {
              id: 3,
              label: "Assigned to",
              value: "user",
              width: 130,
              html: true
            },
            {
              id: 4,
              label: "Type",
              value: "type",
              width: 68
            },
            {
              id: 5,
              label: "%",
              value: "progress",
              width: 35,
              style: {
                "task-list-header-label": {
                  "text-align": "center",
                  width: "100%"
                },
                "task-list-item-value-container": {
                  "text-align": "center",
                  width: "100%"
                }
              }
            }
          ]
        }
      }
    };
  }
};
</script>

<style>
</style>
