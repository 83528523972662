<template>
  <div>
    <button @click="getSelected()">checked</button>
    <button @click="changeref()">change</button>
    <ac-accordion-table
      :stages_url="surl"
      toggle_variant="white"
      append_key="id"
      :table_props="table_data"
      :item_url="item_url"
      @row-clicked="row"
      @dropdown-click="row"
      @checkbox-clicked="row"
      ref="accTest"
    >
      <template #accordion_header="{ item }">
        <div class="float-left">{{item.name}}({{item.count}})</div>
      </template>
    </ac-accordion-table>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      surl:
        "https://handlerstest.500apps.com/v2/participant?fields=name,name%20as%20id",
      table_data: {
        bulk: true,
        dropdown: false,
        columns: [
          {
            label: "Id",
            sortable: true,
            key: "id"
          },
          {
            label: "subject",
            sortable: false,
            key: "subject"
          },
          {
            label: "source",
            sortable: false,
            key: "source"
          },
          {
            label: "created_by",
            sortable: false,
            key: "r_name"
          }
        ],

        show_pagination: true,
        total_count: 20,
        limit: 5,
        limit_key: "limit",
        offset_key: "offset",
        offset: 5,
        ac_cursor: {
          where: "status =%27${id}%27 "
        },
        dropdown_button_options: {
          icon: "fal fa-ellipsis-h",
          variant: "bg-transparent p-0",
          size: "lg",
          right: true
        },
        dropdown_options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt",
            handler: "hello"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt",
            handler: "hello"
          }
        ],
        dropdown: true
      },
      item_url: "https://handlerstest.500apps.com/v2/page_cms_table"
    };
  },
  methods: {
    row: function(data) {
      console.log(data);
    },
    getSelected: function() {
      console.log("ff", this.$refs.accTest.get_selected_all());
      let obj = this.table_data;
      obj.ac_cursor.order_by = "id";
      obj.limit = 2;
      this.table_data = { ...obj };
    },
    changeref: function() {}
  }
};
</script>
