<template>
  <ac-simple-search
    :placeholder="placeholder"
    :search_symbol="true"
    :show_tooltip="true"
    tooltip_message="search symbol"
  ></ac-simple-search>
</template>
<script>
export default {
  data() {
    return {
      placeholder: "Search Something..."
    };
  }
};
</script>