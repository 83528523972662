<template>
  <div>
    HELLO
    <ac-simple-grid :options="options"></ac-simple-grid>
  </div>
</template>
<script>
export default {
    data(){
     return{
        "options":[        
        {
          "columns":  [
            {
                helper:"ac-input",
                class:"col-md-8"
            },
            {
                helper:"ac-radio",
                class:"col-md-2"
            },
            {
                helper:"ac-input",
                class:"col-md-2"
            }
        ] 
        },
        {
          "columns":  [
            {
                helper:"ac-input",
                class:"col-md-2"
            },
            {
                helper:"ac-radio",
                class:"col-md-8"
            },
            {
                helper:"ac-input",
                class:"col-md-2"
            }
        ] 
        },
        {
          "columns":  [
            {
                helper:"ac-input",
                class:"col-md-2"
            },
            {
                helper:"ac-radio",
                class:"col-md-2"
            },
            {
                helper:"ac-input",
                class:"col-md-8"
            }
        ] 
        }
        ]
     
     
     }   
    }
}
</script>