<template>
  <ac-global-search
    ref="global_search"
    :types="[
                { label: 'All', value: 'all', checked: true },
                { label: 'Contacts', value: 'contacts', checked: false },
                { label: 'Message', value: 'message', checked: false }
              ]"
    :url="collection.url"
    :search_symbol="search_symbol"
    :placeholder="placeholder"
    @keyup="event_test"
    @button_click="button_click_event"
  >
    <template #result_body="{ data }">
      <div class="card result-body">
        <template v-for="item in data">
          <p class="cursor" :key="item.name">{{item.name}}</p>
        </template>
      </div>
    </template>
    <template #empty_state>
       <div class="card result-body">
        <p class="text-center">No data exist</p>
      </div>
    </template>
  </ac-global-search>
</template>

<script>
export default {
  data: function() {
    return {
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments"
      },
      search_symbol: false,
      placeholder: "Enter the value"
    };
  },
  methods: {
    event_test(data) {
      debugger;
      console.log("event_test: " + data.Input, data.Types);
    },
    button_click_event(data) {
      console.log("button_click_event: " + data.Input, data.Types);
    }
  }
};
</script>

<style scoped>
.result-body {
  position: absolute;
  left: 16px;
  top: 38px;
  width: 98%;
  z-index: 2;
}
.cursor{
cursor: pointer;
}
</style>
