<template>
  <div>
    <br>
    <br>
    <ac-select v-model="selected" :options="options"></ac-select>
    <ac-select
      v-model="selected"
      :options="options"
      size="sm"
      class="mt-3"
      :select-size="4"
    ></ac-select>

    <div class="mt-3">
      Selected:
      <strong>{{ selected }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: { C: "3PO" },
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "third Option" },
        { value: "e", text: "fourtj Option" },
        { value: "f", text: "fifth Option" },
        { value: "h", text: "This one is disabled", disabled: true }
      ]
    };
  }
};
</script>