<template>
  <div>
    Default
    <ac-onboarding-meter
      ref="OnboardingMeter1"
      :hide_if_complete="true"
      :items="items"
      @item_click="item_click($event)"
    >
      <template slot="header">
        <b>On board</b>
      </template>
      <!-- <template slot="footer">
            <a href="#" class="text-decoration-none">Invite users</a>
      </template>-->
    </ac-onboarding-meter>

    <p>show-badge=false</p>
    <ac-onboarding-meter
      ref="OnboardingMeter2"
      :hide_if_complete="true"
      :show_badge="false"
      :items="middle"
    >
      <template slot="header">
        <b>New Here?</b>
      </template>
      <template slot="footer">
        <small>Invite users</small>
      </template>
    </ac-onboarding-meter>

    <p>show-progress=false</p>
    <ac-onboarding-meter
      ref="OnboardingMeter3"
      :hide_if_complete="true"
      :show_progress="false"
      :items="middle"
    >
      <template slot="header">
        <b>New Here?</b>
      </template>
      <template slot="footer">
        <small>Invite users</small>
      </template>
    </ac-onboarding-meter>

    <p>position bottom</p>
    <ac-onboarding-meter
      ref="OnboardingMeter4"
      :hide_if_complete="true"
      position="bottom"
      :items="middle"
    >
      <template slot="header">
        <b>New Here?</b>
      </template>
      <template slot="footer">
        <a>Invite users</a>
      </template>
    </ac-onboarding-meter>

    <p>hide_if_complete=true</p>
    <ac-onboarding-meter ref="OnboardingMeter5" :hide_if_complete="true" :items="good">
      <template slot="header">
        <b>New Here?</b>
      </template>
      <template slot="footer">
        <a>Invite users</a>
      </template>
    </ac-onboarding-meter>
  </div>
</template>

<script>
export default {
  methods: {
    item_click(item) {
      alert("hi");
      console.log(item);
    }
  },
  data() {
    return {
      hide: [
        {
          name: "Add Signature",
          url:
            "https://sign.500apps.com/get/status?table_name=signature_setting&column_name=created_by",
          description:
            "Go to user dropdown list> signature setting to add signature.",
          help: "/signature/sign",
          icon: "fas fa-signature"
        },
        {
          name: "Field Settings",
          url: "https://sign.500apps.com/get/status2",
          help: "/signature/update",
          description:
            "Go to user dropdown list> signature setting > field settings > to add other feilds.",
          icon: "fas fa-user"
        },
        {
          name: "Add Document",
          url:
            "https://sign.500apps.com/get/status?table_name=document&column_name=user_id",
          help: "/home",
          description: "Home page >choose option > upload to add a document.",
          icon: "far fa-file-signature"
        },
        {
          name: "Add Signee",
          url:
            "https://sign.500apps.com/get/status?table_name=document_signee&column_name=created_by",
          help: "/home",
          description:
            "Choose option Me & Others or Others in homepage > upload doc > add signees.",
          icon: "fas fa-user-edit"
        }
      ],
      middle: [
        {
          name: "Setup DKIM/SPF",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "/check/setup-dkim-spf",
          icon: "fab fa-angellist"
        },
        {
          name: "Setup DKIM/SPF",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "/check/setup-dkim-spf",
          icon: "fab fa-angellist"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        },
        {
          name: "Setup DKIM/SPF",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "/check/setup-dkim-spf",
          icon: "fab fa-angellist"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        }
      ],
      good: [
        {
          name: "Setup DKIM/SPF",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "/check/setup-dkim-spf",
          icon: "fab fa-angellist"
        }
      ],
      items: [
        {
          name: "Setup DKIM/SPF",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "/check/setup-dkim-spf",
          icon: "fab fa-angellist"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fal fa-file "
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        },
        {
          name: "Setup push notifications",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6703100000f3212ce3f",
          help: "https://examples.com",
          icon: "fas fa-bell"
        },
        {
          name: "Add landing page",
          description:
            "Cras sit amet nibh libero,in the control meter gravida nulla.",
          url: "http://www.mocky.io/v2/5cc9f6a0310000891212ce40",
          help: "/check/add-landing-page",
          icon: "fas fa-file"
        }
      ]
    };
  }
};
</script>