<template>
    <ac-html
              :html="html_template"
            />
</template>

<script>
export default {
    data: function () {
        return {
            html_template: '<b-row><b-col><b-card title=\'Card\'><ac-input label=\'Label\'></ac-input></b-card></b-col><b-col></b-col></b-row>',
        }
    }
}
</script>

<style>

</style>
