<template>
  <div>
    <ac-kanban
      :items="kanban.items"
      :draggable="kanban.draggable"
      :positions="kanban.positions"
      :headers_sortable="kanban.headers_sortable"
      item_key="id"
    >
    <template v-slot:header>Main Header</template>
      <template #kanban-item="{ item }">
        <div class="p-2 border mt-2">{{ item.name }}</div>
      </template>

      <template #ref1>
        <ac-collection
          ref="ref1"
          :url="collection.url"
          :method="collection.method"
          :params="collection.params"
          :loading_image="collection.loading_image"
          :sort_key="collection.sort_key"
          :ac_cursor="{
                    postId: 1,
                  }"
        />
      </template>

      <template #ref2>
        <ac-collection
          ref="ref2"
          :url="collection.url"
          :method="collection.method"
          :params="collection.params"
          :loading_image="collection.loading_image"
          :sort_key="collection.sort_key"
          :ac_cursor="{
                    postId: 2,
                  }"
        />
      </template>
      <template v-slot:footer>Main footer</template>
    </ac-kanban>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      kanban: {
        items: {
          a: {
            label: "Cold",
            ac_collection: "ref1"
          },
          b: {
            label: "Contacted",
            ac_collection: "ref2"
          }
        },
        positions: ["a", "b"],
        draggable: true,
        headers_sortable: true
      },
      collection: {
        url: "https://jsonplaceholder.typicode.com/posts/1/comments",
        loading_image:
          "https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif",
        ac_cursor: {
          postId: 1
        },
        method: "GET",
        params: {
          headers: {
            "Custom-Header": "My custom header"
          }
        },
        sort_key: "name"
      }
    };
  }
};
</script>

<style>
</style>
