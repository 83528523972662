<template>
  <div>
    <ac-newsection
      :rule_json="ruleJson"
      :prefill_data="editedData"
      @outputJson="getOutput"
      ref="newsection"
    ></ac-newsection>
    <button @click="getQury"></button>
  </div>
</template>
    <script>
export default {
  methods: {
    getQury: function() {
      console.log(this.$refs.newsection.getSelectedData());
    }
  },
  data() {
    return {
      ruleJson: {
        controls: [
          {
            controlType: "text",
            value: "sjgjdjfkg",
            placeholder: "Enter",
            label: "Name",
            name: "nnnm"
          },
          {
            controlType: "text",
            value: "jhsdfjhsjdfk@gmail.com",
            placeholder: "Enter",
            label: "Email"
          },
          {
            controlType: "text",
            value: "hafjhsjk",
            placeholder: "Enter",
            label: "Last name"
          }
        ]
      },
      editedData: [
        {
          controls: [
            {
              controlType: "text",
              value: "sjgjdjfkg",
              placeholder: "Enter",
              label: "Name",
              name: "ddfsfsdsadsad"
            },
            {
              controlType: "text",
              value: "jhsdfjhsjdfk@gmail.com",
              placeholder: "Enter",
              label: "Email",
              name: "ddfsfsdadw"
            },
            {
              controlType: "text",
              value: "hafjhsjk",
              placeholder: "Enter",
              label: "Last name",
              name: "ddfsfsdzf"
            }
          ]
        }
      ]
    };
  }
};
</script>