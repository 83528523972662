<template>
  <div>
    <b-input-group>
      <ac-mentions
        ref="ac_mentions"
        v-model="composeValue"
        :options="mentionsOptions"
        class="w-100"
        @input="$emit('input', $event)" 
        @enter="$emit('enter', $event)"
      />
    </b-input-group>
    {{composeValue}}
  </div>
</template>

<script>
import MentionOptions from "../src/assets/mentions.json";
export default {
  data: function() {
    return {
      mentionsOptions: MentionOptions,
      composeValue: "hi "
    };
  }
};
</script>
