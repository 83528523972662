<template>
  <ac-vertical-navigation :menu="menu" @collapse="on_collapse" @item_click="on_item_click"/>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          header: true,
          title: "Appup Component Vertical Navigation"
          // component: componentName
          // visibleOnCollapse: true
          // class: ''
          // attributes: {}
        },
        {
          href: "/",
          title: "Dashboard",
          icon: "fa fa-user"
          /* 
                        // custom icon
                        icon: {
                            element: 'span',
                            class: 'fa fa-user',
                            attributes: {}
                        }
                        */
          // disabled: true
          // class: ''
          // attributes: {}
          // alias: '/path'
          /*
                        badge: {
                            text: 'new',
                            class: 'default-badge'
                            // attributes: {}
                            // element: 'span'
                        }
                        */
        },
        {
          title: "Charts",
          icon: "fa fa-chart-area",
          child: [
            {
              href: "/charts/sublink",
              title: "Sub Link"
            }
          ]
        }
      ]
    };
  },
  methods: {
    on_collapse(collapsed) {
      console.log(collapsed);
    },
    on_item_click(event, item) {
      console.log(event);
      console.log(item);
    }
  }
};
</script>

<style>
</style>
