<template>
  <ac-gravatar
    :avatar_props="{ size: 100, rounded: true }"
    email="example@mail.com"
    preload="test"
  />
</template>

<script>
export default {};
</script>

<style>
</style>
