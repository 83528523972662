<template>
    <div>
      <ac-csv-mappingscreen title="Mapping Screen" ref="csvref" :db-columns-list="dbColumnsList" @mappedData="mappingMethod"></ac-csv-mappingscreen>
    </div>
</template>
<script>
export default {
     data(){
         return {
             dbColumnsList:["Firstname","lastname","age"]
         }
     },
     methods:{
         mappingMethod: function(data){
             console.log("final output data is "+ JSON.stringify(data));
         }
     }
}
</script>

