<template>
  <ac-accordion :data="tabs_data.data" :header_slot="true" :footer_slot="true">
    <template v-slot:header>Main Header</template>
    <template #title="{ data }">
      <b-button block variant="info">{{ data.label }}</b-button>
    </template>

    <template #tab_ref_1="{ data }">
      <div>{{ data.body }}</div>
    </template>

    <template #tab_ref_2="{ data }">
      <div class="d-flex">
        <div class="mr-3 align-items-center">{{ data.body }}</div>

        <ac-material-dropdown
          :options="dropdown.options"
          :button_options="dropdown.button_options"
          @item_click="event_test"
          class="ml-auto"
        />
      </div>
    </template>
    <template v-slot:footer>Main footer</template>
  </ac-accordion>
</template>
<script>
export default {
  data() {
    return {
      tabs_data: {
        data: [
          {
            label: "Block",
            body: "Block 1",
            ref: "tab_ref_1"
          },
          {
            label: "Block 2",
            body: "Another block",
            ref: "tab_ref_2"
          }
        ],
        align: "vertical"
      },
      dropdown: {
        options: [
          {
            label: "Edit",
            type: "edit",
            icon: "fas fa-pencil-alt"
          },
          {
            label: "Delete",
            type: "delete",
            icon: "fas fa-trash-alt"
          }
        ],
        button_options: {
          icon: "fas fa-ellipsis-h",
          variant: "bg-transparent p-0",
          size: "sm",
          right: true
        }
      }
    };
  },
  methods: {
    event_test(data) {
      console.log(data);
    }
  }
};
</script>

